import React from "react";
import './style.scss'
import {Container} from "reactstrap";

const TopBanner = () => {
    return <div className={'banner-container'}>
        <Container>
            <h1 className={'lbl-header'}>Welcome to ToolPrefix</h1>
            <p className={'lbl-desc'}>Unlock new possibilities with our online tools designed to simplify your tasks and
                enhance your
                productivity. </p>
        </Container>
    </div>
}

export default TopBanner