import Index from './views/index'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import AboutUs from "./views/aboutUs";
import rs from './const/routes'
import PrivacyPolicy from "./views/privacy-policy";
import Disclaimer from "./views/disclaimer";
import ContactUs from "./views/contactUs";
import YouTubeThumbnailDownloader from "./views/tools/youTubeThumbnailDownloader";
import YouTubeTagExtractor from "./views/tools/youTubeTagExtractor";
import TextEditor from "./views/tools/textEditor";
import EmailEditor from "./views/tools/emailEditor";
import JsonFormatter from "./views/tools/jsonFormatter";
import ImageEditor from "./views/tools/imageEditor";
import TextToBase64 from "./views/tools/textToBase64";
import Base64ToText from "./views/tools/base64ToText";
import QrGenerator from "./views/tools/qrGenerator";
import StringRepeater from "./views/tools/stringRepeater";
import BarcodeGenerator from "./views/tools/barcodeGenerator";
import WhatsappLinkGenerator from "./views/tools/whatsappLinkGenerator";
import JwtDecoder from "./views/tools/jwtDecoder";
import ChartsGenerator from "./views/tools/chartsGenerator";
import WebCameraTester from "./views/tools/webCamTester";
import MicrophoneTester from "./views/tools/microphoneTester";
import ColorPicker from "./views/tools/colorPicker";
import FreepikImageDownloader from "./views/tools/freepikImageDownloader";
import ShareLinkGenerator from "./views/tools/shareLinkGenerator";
import HmsToSeconds from "./views/tools/hmsToSeconds";
import SecondsToHms from "./views/tools/secondsToHms";
import Cookies from "./views/cookies";
import NotFound from "./views/404";
import RandomNumberGenerator from "./views/tools/randomNumberGenerator";
import TextToBcrypt from "./views/tools/textToBcrypt";
import SignatureDrawing from "./views/tools/signatureDrawing";
import FiverrKeywordFinder from "./views/tools/fiverrKeywordFInder";
import MemeGenerator from "./views/tools/memeGenerator";
import IpInformation from "./views/tools/ipInformation";

function App() {
    return (<BrowserRouter>
        <Routes>
            <Route path={rs.home} element={<Index/>}/>
            <Route path={rs.aboutUs} element={<AboutUs/>}/>
            <Route path={rs.privacyPolicy} element={<PrivacyPolicy/>}/>
            <Route path={rs.disclaimer} element={<Disclaimer/>}/>
            <Route path={rs.contactUs} element={<ContactUs/>}/>
            <Route path={rs.youTubeThumbnailDownloader} element={<YouTubeThumbnailDownloader/>}/>
            <Route path={rs.youTubeTagExtractor} element={<YouTubeTagExtractor/>}/>
            <Route path={rs.textEditor} element={<TextEditor/>}/>
            <Route path={rs.emailEditor} element={<EmailEditor/>}/>
            <Route path={rs.jsonFormatter} element={<JsonFormatter/>}/>
            <Route path={rs.imageEditor} element={<ImageEditor/>}/>
            <Route path={rs.textToBase64} element={<TextToBase64/>}/>
            <Route path={rs.base64ToText} element={<Base64ToText/>}/>
            <Route path={rs.qrGenerator} element={<QrGenerator/>}/>
            <Route path={rs.stringRepeater} element={<StringRepeater/>}/>
            <Route path={rs.barcodeGenerator} element={<BarcodeGenerator/>}/>
            <Route path={rs.whatsappLinkGenerator} element={<WhatsappLinkGenerator/>}/>
            <Route path={rs.jwtDecoder} element={<JwtDecoder/>}/>
            <Route path={rs.chartGenerator} element={<ChartsGenerator/>}/>
            <Route path={rs.webCamTest} element={<WebCameraTester/>}/>
            <Route path={rs.micTest} element={<MicrophoneTester/>}/>
            <Route path={rs.colorPicker} element={<ColorPicker/>}/>
            <Route path={rs.freePikImageDownloader} element={<FreepikImageDownloader/>}/>
            <Route path={rs.shareLinkGenerator} element={<ShareLinkGenerator/>}/>
            <Route path={rs.hmsToSeconds} element={<HmsToSeconds/>}/>
            <Route path={rs.secondsToHms} element={<SecondsToHms/>}/>
            <Route path={rs.useOfCookies} element={<Cookies/>}/>
            <Route path={rs.randomNumber} element={<RandomNumberGenerator/>}/>
            <Route path={rs.bcrypt} element={<TextToBcrypt/>}/>
            <Route path={rs.signatureDrawing} element={<SignatureDrawing/>}/>
            <Route path={rs.fiverrKeywordFinder} element={<FiverrKeywordFinder/>}/>
            <Route path={rs.memeGenerator} element={<MemeGenerator/>}/>
            <Route path={rs.ipInfoFinder} element={<IpInformation/>}/>
            <Route path={'*'} element={<NotFound/>}/>
        </Routes>
    </BrowserRouter>);
}

export default App;
