import React, {useRef, useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {FileUploader} from "react-drag-drop-files";
import {Col, Row} from "reactstrap";
import FilerobotImageEditor, {TABS, TOOLS} from 'react-filerobot-image-editor';
import {downloadFile, getBase64} from "../../utils/commonFunc";

const fileTypes = ["JPG", "PNG", "GIF"];
const ImageEditor = () => {
    const [file, setFile] = useState('');
    const handleChange = (file) => {
        getBase64(file)
            .then(result => {
                setFile(result)
            })
    };

    return (<Page id={'8'} title={'Image Editor'}>
        {!file && <Row>
            <Col md={12}>
                <FileUploader handleChange={handleChange} name="file" types={fileTypes}/>
            </Col>
        </Row>}

        {file && <FilerobotImageEditor
            source={file}
            onSave={(editedImageObject) => downloadFile(editedImageObject)}
            annotationsCommon={{
                fill: '#fff'
            }}
            Text={{text: 'ToolPrefix'}}
            Rotate={{angle: 90, componentType: 'slider'}}
            tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]}
            defaultTabId={TABS.ANNOTATE}
            defaultToolId={TOOLS.TEXT}
        />}

        <Row>
            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Our Image Editor is designed to provide a seamless and user-friendly experience, allowing you to
                        insert your images and start editing them in just a few clicks. Whether you're a photography
                        enthusiast, graphic designer, or just someone looking to enhance your images, our Image Editor
                        is
                        the perfect tool for you.
                    </p>

                    <p>
                        With our Image Editor, you have a wide range of editing options at your disposal. From basic
                        adjustments like cropping, resizing, and rotating, to advanced features like filters, effects,
                        and
                        overlays, the possibilities are endless. Unleash your creativity and transform your images into
                        stunning works of art.
                    </p>

                    <p>
                        One of the standout features of our Image Editor is its intuitive interface, making it suitable
                        for
                        both beginners and experienced users. You don't need to be a professional graphic designer to
                        create
                        impressive edits - our Image Editor guides you through the process, step by step.
                    </p>

                    <p>
                        Additionally, our Image Editor supports multiple image formats, so you can edit your photos
                        regardless of whether they are in JPEG, PNG, GIF, or other formats. Plus, it is fully compatible
                        with all devices, allowing you to edit your images on-the-go, whether you're using a desktop,
                        tablet, or smartphone.
                    </p>

                    <h5>Key Features:</h5>

                    <p>
                        <ul>
                            <li>Easy image insertion and editing</li>
                            <li>Basic adjustments: Crop, Resize, Rotate</li>
                            <li>Advanced editing features: Watermark, Draw</li>
                            <li>Intuitive and user-friendly interface</li>
                            <li>Support for multiple image formats</li>
                            <li>Fully responsive for all devices</li>
                        </ul>

                        Experience the power of our Online Image Editor and unlock your creativity with ease. Whether
                        you're
                        looking to enhance your social media photos, create stunning graphics for your website, or
                        simply
                        have fun with image editing, our Image Editor has got you covered.
                    </p>

                    <p>
                        Get started now and elevate your image editing skills to new heights! Let your imagination run
                        wild
                        and turn your ordinary images into extraordinary masterpieces with our Online Image Editor.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default ImageEditor