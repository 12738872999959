import React from "react";
import Page from "../components/page";
import imgBuyMeCoffee from '../assets/img/btn-buy-me-a-coffe.png'
import '../assets/styles/aboutUs.scss'

const AboutUs = () => {
    return <Page socialShare={false} title={'About Us'}>
        <p>ToolPrefix is a free online tools provider, where you can use any online tools without paying charges.
            We are trying to add more tools and make these tools free forever.</p>

        <h5 style={{marginTop: 30}}>Features of ToolPrefix:</h5>

        <p>
            <ul>
                <li> 100% free tools.</li>
                <li>Web app available.</li>
                <li>Directly send feedback to developers.</li>
                <li>Works without internet if web app is installed.</li>
            </ul>

            <p>We are constantly adding new tools and improving previous available tools</p>

            <p>If you are receiving value from our free online tools services, we welcome your support. It really helps
                me a lot. Click on Below Buy Me A Coffee Button to Donate</p>

            <img onClick={() => {
                window.open('https://www.buymeacoffee.com/sahandinukasd', '_blank')
            }} src={imgBuyMeCoffee} alt="btn-buy-me-a-coffee" width={200} className={'btn-buy-coffee'}/>
        </p>
    </Page>
}

export default AboutUs