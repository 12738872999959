import React, {useState} from "react";
import Page from "../components/page";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import imgThankYou from '../assets/img/img-email-success.jpg'

const ContactUs = () => {
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [error, setError] = useState({
        name: false,
        email: false,
        reason: false,
        description: false
    })
    const [form, setForm] = useState({
        name: '',
        email: '',
        reason: '',
        description: ''
    })

    const onSubmit = (e) => {
        e.preventDefault()

        setIsDisabled(true)
        setTimeout(() => {
            setIsSubmitted(true)
        }, 2000)
    }

    return <Page socialShare={false} title={'Contact Us'}>
        <Row>
            <Col className={'align-self-center'} md={{
                offset: 3,
                size: 6
            }}>
                {!isSubmitted ? <form onSubmit={onSubmit}>
                    <FormGroup>
                        <Label>Your Name</Label>
                        <Input disabled={isDisabled} required placeholder={'Ex: John Doe'}/>
                    </FormGroup>

                    <FormGroup>
                        <Label>Your Email</Label>
                        <Input disabled={isDisabled} required type={'email'}
                               placeholder={'Ex: abcd@abc.com'}/>
                    </FormGroup>

                    <FormGroup>
                        <Label>Reason</Label>
                        <Input disabled={isDisabled} required
                               placeholder={'Ex: Webpage loading issue'}/>
                    </FormGroup>

                    <FormGroup>
                        <Label>Description</Label>
                        <Input disabled={isDisabled} required type={'textarea'}
                               placeholder={'Ex: When click logo display white page'}/>
                    </FormGroup>

                    <Button disabled={isDisabled} color={'primary'} style={{width: '100%'}}
                            type={'submit'}>Submit</Button>
                </form> : <img width={'100%'} src={imgThankYou} alt=""/>}
            </Col>
        </Row>
    </Page>
}

export default ContactUs