import React, {useEffect, useRef, useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Alert, Button, Col, Input, Row} from "reactstrap";
import Webcam from "react-webcam";

const WebCameraTester = (props) => {
    const webcamRef = React.useRef(null);

    const getPermission = () => {
        navigator?.getUserMedia({audio: true, video: true}, function (stream) {
            stream.getTracks().forEach(x => x.stop());
        }, err => getPermission());
    }

    // useEffect(() => {
    //     getPermission()
    // }, [])

    return (<Page id={'17'} title={'WebCam Tester'}>
        <Row>
            <Col md={12}>
                <Alert color={'warning'}>If you can't see your webcam? please <b>check permission</b>. Otherwise there
                    will be an <b>issue</b> with your webcam.</Alert>
                <Webcam
                    ref={webcamRef}
                    width={'100%'}
                    height={'auto'}
                />
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Our Webcam Tester offers a hassle-free experience, automatically detecting your webcam and
                        displaying the live video feed directly on the webpage. Whether you're preparing for a video
                        conference, virtual meeting, or simply want to ensure your webcam is working correctly, our
                        user-friendly interface makes it easy.
                    </p>

                    <p>
                        With just one click, you can access the Webcam Tester and instantly see yourself on the screen,
                        ensuring your webcam is up and running. No need to install any software or download applications
                        -
                        our website does all the work for you!
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Automatic webcam detection and live video feed display</li>
                        <li>Hassle-free, no installation required</li>
                        <li>Quick and user-friendly interface</li>
                        <li>Instant access to webcam functionality</li>
                    </ul>

                    <p>
                        Experience the convenience and peace of mind of our Online Webcam Tester today! Ensure your
                        webcam
                        is working correctly and get ready for your next video call with confidence. Get started now and
                        test your webcam's functionality effortlessly with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default WebCameraTester