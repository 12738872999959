import React, {useEffect, useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, Input, Row} from "reactstrap";
import icnFacebook from '../../assets/img/icons/icnFacebook.png'
import icnTwitter from '../../assets/img/icons/icnTwitter.png'
import icnLinkedin from '../../assets/img/icons/icnLinkedin.png'
import icnWhatsapp from '../../assets/img/icons/icnWhatsapp.png'
import icnTelegram from '../../assets/img/icons/icnTelegram.png'
import icnMail from '../../assets/img/icons/icnMail.png'
import {Clipboard} from 'react-feather'
import {urlRegex} from "../../const/regex";
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";

const initialUrl = {
    facebook: '',
    twitter: '',
    linkedin: '',
    whatsapp: '',
    telegram: '',
    email: ''
}
const ShareLinkGenerator = (props) => {
    const [url, setUrl] = useState('')
    const [text, setText] = useState('')
    const [generatedUrls, setGeneratedUrls] = useState(initialUrl)

    const generateLink = () => {
        const isValid = urlRegex.test(url.trim())
        if (!isValid) {
            toast.error(errorMessages.validUrl, {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            setGeneratedUrls({
                facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
                twitter: `https://twitter.com/intent/tweet?url=${url}&text=${text}`,
                linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=&summary=${text}&source=`,
                whatsapp: `https://api.whatsapp.com/send?text=${url}`,
                telegram: `https://t.me/share/url?url=${url}&text=${text}`,
                email: `mailto:?&subject=&body=${text}`
            })
        }
    }

    const copyToClipboard = (value) => {
        if (url.trim() === '') {
            toast.error(errorMessages.generateUrl, {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            navigator?.clipboard?.writeText(value)
            toast.success(errorMessages.clipboardSuccess, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    return (<Page id={'21'} title={'Share Link Generator'}>
        <Row>
            <Col md={6} className={'mb-4'}>
                <h5>URL</h5>
                <Input value={url} onChange={(e) => setUrl(e.target.value)}/>

                <h5 className={'mt-3'}>Text</h5>
                <Input type={'textarea'} onChange={(e) => setText(e.target.value)}
                       value={text} placeholder={'Hi'}/>

                <Button color={'success'} className={'mt-2'} style={{width: '100%'}}
                        onClick={generateLink}>Generate</Button>
                <Button className={'mt-2'} style={{width: '100%'}} onClick={() => {
                    setUrl('')
                    setText('')
                    setGeneratedUrls(initialUrl)
                }}>Clear</Button>
            </Col>

            <Col md={6} className={'mb-4'}>
                <h5>Generated Links</h5>
                <div className={'social-card'}>
                    <img src={icnFacebook} className={'icn-facebook'}/>
                    <Input className={'txt-facebook'} placeholder={'Facebook'} value={generatedUrls?.facebook}/>
                    <div className={'icn-clipboard'} onClick={() => copyToClipboard(generatedUrls?.facebook)}>
                        <Clipboard/></div>
                </div>

                <div className={'social-card'}>
                    <img src={icnTwitter} className={'icn-twitter'}/>
                    <Input className={'txt-twitter'} placeholder={'Twitter'} value={generatedUrls?.twitter}/>
                    <div className={'icn-clipboard'} onClick={() => copyToClipboard(generatedUrls?.twitter)}>
                        <Clipboard/></div>
                </div>

                <div className={'social-card'}>
                    <img src={icnLinkedin} className={'icn-linkedin'}/>
                    <Input className={'txt-linkedin'} placeholder={'LinkedIn'} value={generatedUrls?.linkedin}/>
                    <div className={'icn-clipboard'} onClick={() => copyToClipboard(generatedUrls?.linkedin)}>
                        <Clipboard/></div>
                </div>

                <div className={'social-card'}>
                    <img src={icnWhatsapp} className={'icn-whatsapp'}/>
                    <Input className={'txt-whatsapp'} placeholder={'Whatsapp'} value={generatedUrls?.whatsapp}/>
                    <div className={'icn-clipboard'} onClick={() => copyToClipboard(generatedUrls?.whatsapp)}>
                        <Clipboard/></div>
                </div>

                <div className={'social-card'}>
                    <img src={icnTelegram} className={'icn-telegram'}/>
                    <Input className={'txt-telegram'} placeholder={'Telegram'} value={generatedUrls?.telegram}/>
                    <div className={'icn-clipboard'} onClick={() => copyToClipboard(generatedUrls?.telegram)}>
                        <Clipboard/></div>
                </div>

                <div className={'social-card'}>
                    <img src={icnMail} className={'icn-email'}/>
                    <Input className={'txt-email'} placeholder={'Email'} value={generatedUrls?.email}/>
                    <div className={'icn-clipboard'} onClick={() => copyToClipboard(generatedUrls?.email)}><Clipboard/>
                    </div>
                </div>
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Sharing links to interesting content or important information has never been easier with our
                        user-friendly interface. Whether you want to share a website link, a blog post, or simply some
                        text,
                        our website makes it simple and convenient.
                    </p>

                    <p>
                        With our Share Link Generator, you can enter the link or text you want to share, and our website
                        will generate sharable links for email and popular social media platforms. Simply click on the
                        respective sharing icons, and the link will be automatically copied to your clipboard, ready to
                        be
                        shared with your friends, colleagues, or followers.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Effortless link or text sharing with email and social media</li>
                        <li>Quick and user-friendly interface</li>
                        <li>Automatic link copying for seamless sharing</li>
                        <li>Shareable links for popular social media platforms</li>
                    </ul>

                    <p>
                        Experience the convenience and power of our Online Share Link Generator today! Share links to
                        your
                        favorite content or important information with ease, making sharing content a breeze. Get
                        started
                        now and start sharing with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default ShareLinkGenerator