import {apiService} from "./apiConfig";

export const getMemes = async () => {
    const apiObject = {}
    apiObject.isBaseUrl = false
    apiObject.customUrl = 'https://vermillion-bublanina-56de6a.netlify.app/.netlify/functions/api/meme/get-random-memes'
    apiObject.method = 'get'
    apiObject.loader = true
    return await apiService(apiObject)
}

export const getIpInfo = async (ip) => {
    const apiObject = {}
    apiObject.isBaseUrl = false
    apiObject.customUrl = `https://vermillion-bublanina-56de6a.netlify.app/.netlify/functions/api/ip-info/get-ip-info?ip=${ip}`
    apiObject.method = 'get'
    apiObject.loader = true
    return await apiService(apiObject)
}