import React, {useRef, useState} from "react";
import Page from "../../components/page";
import {Button, Col, Input, Row} from "reactstrap";
import EmailEditor from 'react-email-editor';
import '../../assets/styles/common.scss'
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";


const EmailEditorView = (props) => {
    const emailEditorRef = useRef(null);
    const [htmlData, setHtmlData] = useState(null)

    const exportHtml = async () => {
        await emailEditorRef.current.editor.exportHtml(async (data) => {
            const {design, html} = data;
            console.log('exportHtml', html);

            // navigator?.clipboard?.writeText(html)
            // toast.success(errorMessages.clipboardSuccess, {
            //     position: toast.POSITION.TOP_CENTER
            // })
        });
    };

    const [isReady, setIsReady] = useState(false)

    const onReady = () => {
        setIsReady(true)
        // editor is ready
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    };

    return (<Page>
        <Row>
            {isReady && <Col md={{
                size: 6,
                offset: 3
            }}>
                <Button onClick={exportHtml} color={'primary'}
                        style={{width: '100%'}}>Generate HTML</Button>
                <a href={`data:text/html ${htmlData}`}>
                    <Button color={'primary'} style={{width: '100%'}}>Download
                        HTML</Button></a>
            </Col>}
            <Col md={12} style={{position: 'relative'}}>
                <EmailEditor ref={emailEditorRef} onReady={onReady} style={{minHeight: 800, marginTop: 20}}/>
            </Col>
        </Row>
    </Page>);
}

export default EmailEditorView