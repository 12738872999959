export default {
    validUrl: 'Please enter a valid URL',
    toolAddedSuccess: 'Added to list',
    toolRemovedSuccess: 'Removed from list',
    somethingWrong: 'Something went wrong',
    clipboardSuccess: 'Successfully Copied',
    validBase64: 'Please enter valid bas64 text',
    validJwt: 'Please enter valid JWT token',
    validText: 'Please enter a valid text',
    character50Error: 'Please use less than 50 characters',
    numberAndMessage: 'Please enter number and message',
    lastRow: `Can't remove last row`,
    generateUrl: 'Please generate a url',
    validTime: 'Please enter a valid time',
    bcryptMatch: 'Hash is match with your string',
    bcryptNotMatch: 'Hash is not match with your string'
}