import React, {useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, Input, Label, Row} from "reactstrap";
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";

const StringRepeater = (props) => {
    const [typeText, setTypeText] = useState('')
    const [data, setData] = useState('')
    const [repeats, setRepeats] = useState(1)
    const [seperator, setSeperator] = useState('')

    const generateText = () => {
        if (typeText.trim() === '') {
            toast.error(errorMessages.validText, {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            let finalValue = ``
            for (let i = 0; i < repeats; i++) {
                finalValue += `${i !== 0 ? seperator !== '' ? `${seperator} ` : '' : ''}${typeText}`
            }
            setData(finalValue)
        }
    }

    const isDisabled = typeText.trim().length === 0

    return (<Page id={'12'} title={'String Repeater'}>
        <Row>
            <Col md={6} className={'mb-4'}>
                <h5>Your Text</h5>
                <Input type={'textarea'} className={'height-250'}
                       onChange={(e) => setTypeText(e.target.value)}
                       value={typeText}/>

                <Row style={{marginTop: 20}}>
                    <Col md={6}>
                        <h5>No of Repeats</h5>
                        <Input onChange={(e) => setRepeats(Number.parseInt(e.target.value))} value={repeats}
                               type={'number'}/>
                    </Col>

                    <Col md={6}>
                        <h5>Repeat Separator</h5>
                        <Input onChange={(e) => setSeperator(e.target.value)} value={seperator}/>
                    </Col>
                </Row>

                <Button disabled={isDisabled} color={'success'} className={'mt-3'} onClick={generateText}
                        style={{width: '100%'}}>Generate</Button>
                <Button className={'mt-2'} disabled={isDisabled} style={{width: '100%'}} onClick={() => {
                    setData('')
                    setTypeText('')
                    setRepeats(1)
                    setSeperator('')
                }}>Clear</Button>
            </Col>

            <Col md={6} className={'mb-4'}>
                <h5>Generated Text</h5>
                <Input type={'textarea'} style={{height: 393}} value={data} readOnly/>
                <Button color={'primary'} className={'mt-2 btn-clipboard'} style={{width: '100%'}} onClick={() => {
                    navigator?.clipboard?.writeText(data)
                    toast.success(errorMessages.clipboardSuccess, {
                        position: toast.POSITION.TOP_CENTER
                    })
                }} disabled={data === ''}>Copy to Clipboard</Button>
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Whether you're looking to create duplicate strings for programming purposes, generate repetitive
                        patterns, or simply have fun with text manipulation, our user-friendly interface makes it easy
                        and
                        convenient.
                    </p>

                    <p>
                        With our String Repeater, you can enter any text or phrase that you wish to repeat, and specify
                        the
                        desired number of repetitions. Additionally, you have the freedom to add a custom separator
                        between
                        each repeated instance, giving you full control over the output format.
                    </p>

                    <p>
                        The process is as simple as typing in your text, choosing the repeat count, and customizing the
                        separator - with a click of a button, your customized repeated string is generated and ready for
                        use!
                    </p>

                    <p>
                        Our Online String Repeater ensures a quick and reliable repetition process, catering to your
                        specific needs without the need for technical expertise or software installation.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Effortless text repetition with custom count and separator</li>
                        <li>Quick and user-friendly interface</li>
                        <li>Full control over output format</li>
                        <li>Reliable and efficient repetition process</li>
                    </ul>

                    <p>
                        Experience the convenience and power of our Online String Repeater today! Unleash your
                        creativity,
                        manipulate text effortlessly, and make repetitive tasks a breeze. Get started now and discover
                        the
                        limitless possibilities of text repetition with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default StringRepeater