import React, {useEffect, useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, Input, Row} from "reactstrap";
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";
import PhoneInput from 'react-phone-number-input'
import {getVisitorData} from "../../services/commonServices";

const WhatsappLinkGenerator = (props) => {
    const [data, setData] = useState('')
    const [typeText, setTypeText] = useState('')
    const [message, setMessage] = useState('')

    const generateLink = () => {
        if (typeText.trim() !== '' && message.trim() !== '') {
            setData(`https://wa.me/${typeText}?text=${message}`)
        } else {
            toast.warning(errorMessages.numberAndMessage, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    const isDisabled = message.trim().length === 0 && typeText.trim().length === 0

    return (<Page id={'14'} title={'Whatsapp Link Generator'}>
        <Row>
            <Col md={6} className={'mb-4'}>
                <h5>Whatsapp Number</h5>
                <PhoneInput
                    style={{marginBottom: 20}}
                    placeholder="Enter phone number"
                    value={typeText}
                    onChange={setTypeText}
                    countryCallingCodeEditable={false}
                    international
                    defaultCountry={'US'}
                />

                <h5>Message</h5>
                <Input type={'textarea'} className={'height-200'}
                       onChange={(e) => setMessage(e.target.value)}
                       value={message} placeholder={'Hi'}/>

                <Button disabled={isDisabled} color={'success'} className={'mt-2'} style={{width: '100%'}}
                        onClick={generateLink}>Generate</Button>
                <Button disabled={isDisabled} className={'mt-2'} style={{width: '100%'}} onClick={() => {
                    setData('')
                    setTypeText('')
                }}>Clear</Button>
            </Col>

            <Col md={6} className={'mb-4'}>
                <h5>Generated Link</h5>
                <Input disabled={data.trim() === ''} type={'textarea'} style={{height: 100}} value={data} readOnly/>
                <Button disabled={data.trim() === ''} color={'primary'} className={'mt-2'} style={{width: '100%'}}
                        onClick={() => {
                            window.open(data, '_blank')
                        }}>Open on Whatsapp</Button>
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Sending WhatsApp messages has never been easier with our user-friendly interface. Simply enter a
                        valid WhatsApp available phone number and country code, along with the message you wish to send.
                        Our
                        website will generate a special link that, when pasted into your mobile or PC, will
                        automatically
                        navigate to WhatsApp and add your message to the chat. All you need to do is click the send
                        button!
                    </p>

                    <p>
                        Whether you're a business owner, communicator, or just someone looking to connect with others
                        via
                        WhatsApp, our website offers a seamless and efficient solution. You can easily send messages to
                        new
                        contacts without the need to save their numbers beforehand.
                    </p>

                    <p>
                        Our Online WhatsApp Link Generator ensures a quick and reliable link generation process. No need
                        to
                        go through the hassle of saving numbers or searching through contacts - simply type, click, and
                        your
                        WhatsApp message is ready to be sent!
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Effortless WhatsApp message sending without saving contacts</li>
                        <li>Quick and user-friendly interface</li>
                        <li>Automatic message insertion in WhatsApp chat</li>
                        <li>No need to install any software</li>
                        <li>Reliable and efficient link generation process</li>
                    </ul>

                    <p>
                        Experience the convenience and power of our Online WhatsApp Link Generator today! Send WhatsApp
                        messages with ease, making communication a breeze. Get started now and connect with others
                        seamlessly with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default WhatsappLinkGenerator