import React, {useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";
import strings from '../../const/strings'
import Select from "react-select";

const types = [
    {label: 'Decimal', value: 'DECIMAL'},
    {label: 'Float', value: 'FLOAT'},
    {label: 'Binary', value: 'BINARY', digit: 2},
    {label: 'Octal', value: 'OCTAL', digit: 8},
    {label: 'Hexadecimal', value: 'HEX', digit: 16},
]
const RandomNumberGenerator = (props) => {
    const [selectedType, setSelectedType] = useState(types[0])
    const [from, setFrom] = useState(1)
    const [to, setTo] = useState(100)
    const [points, setPoints] = useState(2)
    const [generatedNumber, setGeneratedNumber] = useState(0)

    const randomInt = () => {
        return Math.floor(Math.random() * (to - from + 1)) + from;
    }

    const generate = () => {
        const type = selectedType.value
        let number = 0
        switch (type) {
            case 'DECIMAL':
                number = randomInt()
                break

            case 'FLOAT':
                number = (Math.random() * (to - from) + from).toFixed(points)
                break

            case 'BINARY':
                number = (randomInt() >>> 0).toString(2)
                break

            case 'OCTAL':
                number = (randomInt()).toString(8)
                break

            case 'HEX':
                number = (randomInt()).toString(16)
                break
        }

        setGeneratedNumber(number)
    }

    return (<Page id={'24'} title={strings.randomNumberGenerator}>
        <Row style={{alignItems: 'center'}}>
            <Col md={6} className={'mb-4'}>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Number Type</Label>
                            <Select value={selectedType} options={types} onChange={e => {
                                setSelectedType(e)
                                setGeneratedNumber(0)
                            }}/>
                        </FormGroup>
                    </Col>

                    <Col md={selectedType === types[1] ? 2 : 3}>
                        <FormGroup>
                            <Label>From</Label>
                            <Input value={from} onChange={e => setFrom(Number.parseInt(e.target.value))}
                                   type={'number'}/>
                        </FormGroup>
                    </Col>

                    <Col md={selectedType === types[1] ? 2 : 3}>
                        <FormGroup>
                            <Label>To</Label>
                            <Input value={to} onChange={e => setTo(Number.parseInt(e.target.value))}
                                   type={'number'}/>
                        </FormGroup>
                    </Col>

                    {selectedType === types[1] && <Col md={2}>
                        <FormGroup>
                            <Label>Points</Label>
                            <Input value={points} onChange={e => setPoints(Number.parseInt(e.target.value))}
                                   type={'number'}/>
                        </FormGroup>
                    </Col>}

                    <Col md={12}>
                        <Button onClick={generate} style={{width: '100%'}} color={'primary'}>Generate</Button>
                    </Col>
                </Row>
            </Col>

            <Col md={6} className={'mb-2'} align={'center'}>
                <h5>Generated Number</h5>
                <h1>{generatedNumber} {selectedType?.digit && <sub>{selectedType.digit}</sub>}</h1>
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Our user-friendly interface allows you to select the number type, including decimal, float,
                        binary,
                        octal, and hexadecimal, and specify a range. With just a click, our website generates a random
                        number within the specified range and displays it instantly.
                    </p>

                    <p>
                        Whether you're a developer, statistician, or just someone in need of a random number for a game
                        or
                        experiment, our tool offers seamless and efficient random number generation.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Effortless random number generation in various formats</li>
                        <li>Quick and user-friendly interface</li>
                        <li>Customizable range selection</li>
                        <li>Instant display of generated random numbers</li>
                    </ul>

                    <p>
                        Experience the convenience and power of our Online Random Number Generator today! Generate
                        random
                        numbers for various applications with ease, making random number generation a breeze. Get
                        started
                        now and unlock the potential of random number generation with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default RandomNumberGenerator