import axios from 'axios';
import {toast} from "react-hot-toast";
import {generateUniqueId} from "../utils/commonFunc";
import $ from 'jquery'

export const apiService = async (apiObject) => {
    apiObject.loader && $(".loader-container").css("display", "block")
    const url = apiObject?.isBaseUrl ? apiObject?.isBaseUrl : apiObject?.customUrl
    let result = undefined
    const headers = {
        "user-id": await generateUniqueId()
    }
    await axios({
        headers,
        url,
        method: apiObject.method
    })
        .then(async response => {
            result = await response
            $(".loader-container").css("display", "none")
            // do something with JSON response data
        }).catch(error => {
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : error.message)
            result = error
            $(".loader-container").css("display", "none")
        })

    return result
}