import React, {useState} from "react";
import Page from "../../components/page";
import {Button, Col, Row} from "reactstrap";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import '../../assets/styles/common.scss'
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";

const TextEditor = (props) => {
    const [value, setValue] = useState('')

    const modules = {
        toolbar: [
            [{font: []}],
            [{'header': [1, 2, 3, 4, 5, false]}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
            [{color: []}, {background: []}],
            [{script: "sub"}, {script: "super"}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean']
        ],
    }

    const copyToClipboard = () => {
        toast.success(errorMessages.clipboardSuccess, {
            position: toast.POSITION.TOP_CENTER
        })
    }

    return <Page id={'4'} title={'Text Editor'}>
        <Row>
            <Col md={9} style={{marginBottom: 20}}>
                <ReactQuill className={'text-editor'} theme="snow" value={value} onChange={setValue} modules={modules}/>
            </Col>

            {value.length > 0 && <Col md={3}>
                <h4>HTML Code</h4>
                <div style={{wordWrap: 'break-word', maxHeight: 560, overflowY: 'scroll'}}>{value}</div>

                <div align={'center'} style={{marginTop: 9}}>
                    <CopyToClipboard text={value} onCopy={copyToClipboard}>
                        <Button style={{width: '100%'}} className={'btn-clipboard'}>Copy to Clipboard</Button>
                    </CopyToClipboard>
                </div>
            </Col>}

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Our Text Editor provides a seamless and intuitive interface, allowing users to type and edit
                        text while
                        offering a plethora of formatting options at your fingertips. Whether you're a student,
                        professional, or
                        blogger, our Text Editor is perfect for crafting beautifully formatted documents, blog posts, or
                        even HTML
                        code.
                    </p>

                    <p>
                        With just a few clicks, you can enhance your text with various formatting styles, including
                        bold, italic,
                        underline, and strikethrough, to make your content stand out. Organize your thoughts
                        effortlessly by adding
                        bullets and numbering to create structured lists. You can even insert images to complement your
                        text and add
                        visual appeal to your work.
                    </p>

                    <p>
                        What sets our Text Editor apart is its intelligent auto-generation of HTML content as you type.
                        With this
                        feature, you don't need to worry about manually coding HTML tags - our Text Editor does it all
                        for you!
                        Simply focus on your content and watch as the HTML code is automatically generated in real-time.
                    </p>

                    <p>
                        Additionally, our Text Editor is fully responsive, ensuring a seamless user experience across
                        all devices,
                        whether you're using a desktop computer, tablet, or smartphone.
                    </p>

                    <h5 className={'mt-4'}>Key Features:</h5>
                    <p>
                        <ul>
                            <li>Real-time formatting options: Bold, Italic, Underline, Strikethrough</li>
                            <li>Organize with Bullets and Numbering</li>
                            <li>Easy image insertion for visual appeal</li>
                            <li>Auto-generation of HTML content</li>
                            <li>Fully responsive for all devices</li>
                        </ul>

                        Whether you're a novice or seasoned coder, our Online Text Editor website is a versatile tool
                        designed to
                        simplify your content creation process. Create stunning text documents, blog posts, and HTML
                        content
                        effortlessly, saving you time and effort.
                    </p>

                    <p>
                        Experience the power of our Online Text Editor and elevate your content creation to new heights.
                        Get started
                        now and unleash your creativity!
                    </p>
                </div>
            </Col>
        </Row>
    </Page>
}

export default TextEditor