import React from "react";
import './style.scss'
import {Link} from "react-router-dom";
import icnNew from '../../assets/img/icons/icnNew.png'
import {Badge} from "reactstrap";

const Card = ({image, title, url, id, isNew, desc}) => {
    return <Link to={url} className={'url'} state={{id, title}}>
        <div className={'card-container d-flex align-items-center'}>
            <div className={'d-flex justify-content-between img-container'}>
                <img src={image} alt="icn-card"/>
                {isNew && <img className={'icn-new'} src={icnNew} alt="icn-new"/>}
            </div>
            {/*{desc && <p className={'mt-3 lbl-desc'}>{desc.substring(0, 90)}...</p>}*/}
            <label className={'lbl-title'}>{title}</label>
        </div>
    </Link>
}

export default Card