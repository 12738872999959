import React, {CSSProperties} from 'react'
import './style.scss'
import PuffLoader from "react-spinners/FadeLoader";

const Loader = () => {
    return <div className={'loader-container'}>
        <div className={'loader'}>
            <PuffLoader
                color={'#fff'}
                loading={true}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    </div>
}

export default Loader