import React, {useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, Input, Row} from "reactstrap";
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";
import {jwtToken} from "../../const/regex";
import jwtDecode from "jwt-decode";
import ReactJson from 'react-json-view'

const JwtDecoder = (props) => {
    const [data, setData] = useState(null)
    const [typeText, setTypeText] = useState('')

    const onDecode = () => {
        const isCorrect = jwtToken.test(typeText)
        if (isCorrect) {
            try {
                const decoded = jwtDecode(typeText);
                setData(decoded)
            } catch (e) {
                toast.error(errorMessages.validJwt, {
                    position: toast.POSITION.TOP_CENTER
                })
            }
        } else {
            toast.error(errorMessages.validJwt, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    const isDisabled = typeText.trim().length === 0

    return (<Page id={'15'} title={'JWT Decoder'}>
        <Row>
            <Col md={6} className={'mb-4'}>
                <h5>JWT Token</h5>
                <Input type={'textarea'} className={'height-400'}
                       onChange={(e) => setTypeText(e.target.value)}
                       value={typeText}/>

                <Button disabled={isDisabled} color={'success'} onClick={onDecode} className={'mt-3'}
                        style={{width: '100%'}}>Decode</Button>
                <Button disabled={isDisabled} className={'mt-2'} style={{width: '100%'}} onClick={() => {
                    setData(null)
                    setTypeText('')
                }}>Clear</Button>
            </Col>

            <Col md={6} className={'mb-4'}>
                <h5>Decoded Data</h5>
                {data ? <ReactJson
                    src={data}
                    style={{width: '100%', wordBreak: 'break-all'}}
                /> : <div>No data available</div>}
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        JWT (JSON Web Tokens) is a widely used method for securely transmitting information between
                        parties.
                        However, decoding JWT tokens manually can be a complex and time-consuming process. With our
                        user-friendly interface, you can now decode JWT tokens and retrieve their details in JSON format
                        with just a simple paste!
                    </p>

                    <p>
                        Whether you're a developer, security analyst, or just someone working with JWT tokens, our
                        website
                        offers a seamless and efficient solution. Simply paste the JWT token into the designated area,
                        and
                        our website will instantly decode it and present the token's details in a human-readable JSON
                        format.
                    </p>

                    <p>
                        Our Online JWT Decoder ensures a quick and reliable decoding process, saving you time and
                        effort.
                        You don't need to be a JWT expert or install any software - just paste the token, and the
                        decoded
                        information will be at your fingertips!
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Effortless JWT token decoding to JSON data</li>
                        <li>Quick and user-friendly interface</li>
                        <li>No installation or technical expertise required</li>
                        <li>Reliable and efficient decoding process</li>
                    </ul>

                    <p>
                        Experience the convenience and power of our Online JWT Decoder today! Decode JWT tokens and
                        extract
                        their details to JSON data with ease, enhancing your data analysis and security efforts. Get
                        started
                        now and simplify your JWT decoding process with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default JwtDecoder