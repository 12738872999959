import React, {useState} from "react";
import {Badge} from 'reactstrap';
import './style.scss'
import {CATEGORIES, CATEGORIES_LIST} from "../../const/const";

const CategoryBadge = (props) => {
    const [active, setActive] = useState(CATEGORIES.ALL)

    const onChange = (item) => {
        const list = props.toolList
        let found = []
        if (item === CATEGORIES.ALL) {
            found = list
        } else {
            found = list.filter(itm => itm.category === item)
        }

        setActive(item)
        props.onChange(found)
    }

    return <div {...props} className={`${props.className} category-container`}>
        {
            CATEGORIES_LIST.map(item => <Badge
                color={item === active ? "primary" : 'secondary'}
                pill
                className={'itm-badge'}
                onClick={() => onChange(item)}
            >{item}</Badge>)
        }
    </div>
}

export default CategoryBadge