import React, {useState} from "react";
import Page from "../../components/page";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {youtubeUrl} from '../../const/regex'
import '../../assets/styles/common.scss'
import {toast} from 'react-toastify';
import errorMessages from "../../const/errorMesages";
import {youTubeGetID} from "../../utils/commonFunc";
import axios from 'axios';
import {key} from '../../const/const'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const YouTubeTagExtractor = () => {
    const baseURL = 'https://www.googleapis.com/youtube/v3/videos?part=snippet'
    const thumbnailBaseURL = 'https://img.youtube.com/vi/'

    const [url, setUrl] = useState(null)
    const [tags, setTags] = useState([])
    const [isFetched, setIsFetched] = useState(false)
    const [thumbnail, setThumbnail] = useState(null)
    const [clipboardData, setClipboardData] = useState(null)

    const onUrlHandler = () => {
        const isValid = youtubeUrl.test(url?.trim())
        if (isValid) {
            const videoId = youTubeGetID(url)
            axios.get(`${baseURL}&id=${videoId}&key=${key}`).then(res => {
                if (res.status === 200) {
                    const data = res?.data?.items[0]?.snippet?.tags
                    let tempTags = ''

                    data.map((item, i) => {
                        tempTags += (i === 0) ? item : `, ${item}`
                    })

                    setTags(data)
                    setClipboardData(tempTags)
                }
            })
            setThumbnail(`${thumbnailBaseURL}${videoId}/maxresdefault.jpg`)
            setIsFetched(true)
        } else {
            toast.error(errorMessages.validUrl, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    const clear = () => {
        setIsFetched(false)
        setTags([])
        setUrl(null)
    }

    const copyToClipboard = () => {
        let tempTags = ''
        tags.map((item, i) => {
            tempTags += (i === 0) ? item : `, ${item}`
        })

        setClipboardData(tempTags)
        toast.success(errorMessages.clipboardSuccess, {
            position: toast.POSITION.TOP_CENTER
        })
    }

    const innerWidth = window.innerWidth

    return <Page id={'2'} title={'YouTube Tag Extractor'}>
        <Row>
            <Col md={12}>
                {!isFetched ? <div className={'url-section'} align={'center'}>
                    <Label>Enter the link of a YouTube video below</Label>
                    <Input autoFocus onChange={(e) => setUrl(e.target.value)}
                           placeholder={'Copy & Past Any YouTube Video Link Here '}/>
                    <Button onClick={onUrlHandler} color={'primary'}>Fetch Tags</Button>
                </div> : <div className={'preview-section'} align={'center'}>
                    <div style={{marginBottom: 20}}>
                        <Button onClick={clear} className={'btn-fetch'}>Fetch Another Tags</Button>
                        {clipboardData && <CopyToClipboard text={clipboardData} onCopy={copyToClipboard}>
                            <Button className={'btn-clipboard'}>Copy to Clipboard</Button>
                        </CopyToClipboard>}
                    </div>

                    <Row>
                        <Col md={12} lg={4} className={'d-flex align-items-center'}>
                            <img className={'tag-preview'} src={thumbnail} alt="video-thumbnail" width={'100%'}
                                 style={{borderRadius: 6}}/>
                        </Col>
                        <Col md={12} lg={8} className={'d-flex align-items-center justify-content-center'}>
                            <div className={'tag-container'}
                                 style={{justifyContent: innerWidth > 992 ? 'left' : 'center'}}>
                                {tags.length > 0 ? tags.map((item, i) => <div key={i}
                                                                              className={'tag-card'}>{item}</div>) :
                                    <div className={'lbl-no-tags'}>No tags found</div>}
                            </div>
                        </Col>
                    </Row>
                </div>}
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <p>
                        Are you struggling to find the right tags for your YouTube videos? Look no further! Our simple
                        and efficient
                        platform allows you to extract all the tags added by the channel when they uploaded that
                        specific video.
                    </p>

                    <h5>Here's how it works: </h5>
                    <p>
                        Copy the link of the YouTube video you want to explore the tags for, then paste it into
                        the provided box on our website. Click the "Fetch Tags" button, and like magic, our powerful
                        system will
                        gather all the available tags from that video.
                    </p>

                    <p>
                        No more guessing which tags to use or spending hours researching keywords. Our Tag Extractor
                        gives you a
                        quick and comprehensive list of tags used by the video's uploader, ensuring you optimize your
                        own content
                        for better discoverability.
                    </p>

                    <p>
                        Why are tags crucial for your YouTube success? Tags help YouTube's algorithm understand the
                        content of your
                        videos and match them with relevant search queries. By using the right tags, you can increase
                        your video's
                        visibility, attract more viewers, and grow your channel.
                    </p>

                    <p>
                        Whether you're a content creator, marketer, or just curious about your favorite videos' tags,
                        our Tag
                        Extractor is an invaluable tool. It's perfect for gaining insights into successful video tagging
                        strategies
                        and applying them to your own content.
                    </p>

                    <p>
                        Give it a try now! Paste the YouTube video link, click "Fetch Tags," and unlock a treasure trove
                        of valuable
                        tags that can boost your YouTube presence. Embrace the power of effective tagging with our
                        user-friendly and
                        reliable Tag Extractor. Happy tagging!
                    </p>

                    <br/>
                    <div>
                        <h5>Video tutorial</h5>
                        <iframe src="https://www.youtube.com/embed/AH1Tq3hxIk8"
                                title="How to get YouTube video tags from toolprefix" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                                className={'youtube-video'}
                        ></iframe>
                    </div>
                </div>
            </Col>
        </Row>
    </Page>
}

export default YouTubeTagExtractor