import React from "react";
import Page from "../components/page";
import '../assets/styles/aboutUs.scss'
import notFound from '../assets/img/404.png'
import {Button} from "reactstrap";
import {Link} from "react-router-dom";

const NotFound = () => {
    return <Page socialShare={false} navigator={false} footer={false}>
        <div align={'center'}>
            <img src={notFound} alt="page-not-found" width={300}/>
            <h4>Page not found!</h4>
            <Link to={'/'}>
                <Button color={'primary'} style={{margin: '20px 0'}}>Back to Home</Button>
            </Link>
        </div>
    </Page>
}

export default NotFound