import React, {useEffect, useRef, useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Alert, Button, Col, Input, Row} from "reactstrap";
import {ReactMic} from 'react-mic';

const MicrophoneTester = (props) => {
    const [record, setRecord] = useState(false)
    const [recordedData, setRecordedData] = useState(null)
    const [recordStarted, setRecordStarted] = useState(false)

    const onStop = (d) => {
        setRecordedData(d.blobURL)
    }

    return (<Page id={'18'} title={'Microphone Tester'}>
            <Row>
                <Col md={12}>
                    <Alert color={'warning'}>If you can't record/hear any voice? please <b>check permission</b>.
                        Otherwise
                        there will be an <b>issue</b> with your microphone.</Alert>
                </Col>

                <Col md={{
                    size: 6,
                    offset: 3
                }}>
                    <ReactMic
                        record={record}
                        className="mic-container"
                        onStop={onStop}
                        strokeColor="#000000"
                        backgroundColor="#E6E6E6"
                        bitRate={256000}
                        sampleRate={96000}
                        timeSlice={3000}
                    />

                    {(!recordStarted && recordedData !== null) &&
                        <audio hidden autoPlay style={{width: '100%'}} controls src={recordedData}/>}

                    <Row className={'mic-btn-container'}>
                        <Col md={12}>
                            {recordStarted ? <>
                                <Button color={'danger'} onClick={() => {
                                    setRecord(false)
                                    setRecordStarted(false)
                                }} type="button">Stop</Button>
                            </> : recordedData !== null ? <Button color={'primary'} onClick={() => {
                                setRecord(true)
                                setRecordStarted(true)
                            }} type="button">Record Again</Button> : <Button color={'success'} onClick={() => {
                                setRecord(true)
                                setRecordStarted(true)
                            }} type="button">Start</Button>}
                        </Col>
                    </Row>
                </Col>

                <Col md={12} className={'mt-4'}>
                    <hr/>
                    <div className={'mt-3'}>
                        <h5>Here's how it works: </h5>
                        <p>
                            Our Microphone Tester offers a seamless experience, allowing you to record audio from your
                            microphone with just one click of the "Start" button. After recording, the audio is
                            automatically played back, ensuring your microphone is working correctly.
                        </p>

                        <p>
                            Whether you're preparing for a virtual meeting, online interview, or simply want to check
                            your
                            microphone's performance, our user-friendly interface makes it easy.
                        </p>

                        <p>
                            With just one click, you can access the Microphone Tester and instantly start recording
                            audio.
                            Once you're done, the recorded audio is played back, allowing you to hear the quality of the
                            microphone in real-time.
                        </p>

                        <h5 className={'mt-2'}>Key Features:</h5>

                        <ul>
                            <li>Effortless microphone testing with record and playback functionality</li>
                            <li>Quick and user-friendly interface</li>
                            <li>Automatic start and stop controls for seamless testing</li>
                            <li>Instant access to microphone functionality</li>
                        </ul>

                        <p>
                            Experience the convenience and assurance of our Online Microphone Tester today! Ensure your
                            microphone is functioning correctly and be ready for your next audio-related task with
                            confidence. Get started now and test your microphone effortlessly with our user-friendly
                            tool.
                        </p>
                    </div>
                </Col>
            </Row>
        </Page>
    );
}

export default MicrophoneTester