import React, {useRef, useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import CanvasDraw from "react-canvas-draw";
import {Checkbox, FormControlLabel, Slider} from "@mui/material";
import {HuePicker} from 'react-color';
import {downloadFile} from "../../utils/commonFunc";

const ImageEditor = () => {
    const [brushSize, setBrushSize] = useState(2)
    const [lazyRadius, setLazyRadius] = useState(0)
    const [color, setColor] = useState({
        hex: '#000'
    })
    const canvasRef = useRef(null);

    const onSave = () => {
        const base64 = canvasRef.current.canvasContainer.childNodes[1].toDataURL();
        downloadFile({imageBase64: base64, name: 'signature-drawing-board', extension: 'png'})
    }

    return (<Page id={'8'} title={'Signature Drawing Board'}>
        <Row>
            <Col md={6} className={'border mb-4'}>
                <CanvasDraw
                    brushRadius={brushSize}
                    lazyRadius={lazyRadius}
                    brushColor={color.hex}
                    style={{width: '100%'}}
                    hideGrid={true}
                    ref={canvasRef}
                />
            </Col>

            <Col md={6}>
                <div style={{margin: '0 20px'}}>
                    <FormGroup>
                        <Label>Brush Color</Label>
                        <HuePicker color={color.hex} onChange={setColor}/>
                    </FormGroup>

                    <FormGroup style={{marginTop: 40}}>
                        <Label>Brush Radius</Label>
                        <Slider aria-label="Brush Radius" valueLabelDisplay="auto" value={brushSize}
                                onChange={(e) => setBrushSize(e.target.value)}/>
                    </FormGroup>

                    <FormGroup>
                        <Label>Lazy Radius</Label>
                        <Slider aria-label="Lazy Size" valueLabelDisplay="auto" value={lazyRadius}
                                onChange={(e) => setLazyRadius(e.target.value)}/>
                    </FormGroup>

                    <FormGroup style={{marginTop: 20}}>
                        <Button color={'primary'} onClick={onSave} style={{width: '100%'}}>Save as an Image</Button>
                    </FormGroup>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Our Signature Creator is designed to provide a seamless and user-friendly experience, allowing
                        you to craft your unique digital signature with ease. Whether you're a professional document
                        signer, an artist seeking a digital touch, or simply someone looking to make their mark in the
                        digital world, our Signature Creator is the perfect tool for you.
                    </p>

                    <p>
                        With our Signature Creator, you have a versatile set of options at your fingertips. From
                        adjusting the brush size to changing colors and adding personal flourishes, the possibilities
                        are boundless. Unleash your creativity and design a signature that truly reflects your identity.
                    </p>

                    <p>
                        One of the standout features of our Signature Creator is its intuitive interface, catering to
                        both novices and seasoned users alike. You don't need to be an expert in calligraphy to create a
                        striking digital signature - our tool gently guides you through the process, step by step.
                    </p>

                    <p>
                        Whether you're using a mouse on your computer or your smartphone's touchscreen, our Signature
                        Creator is fully compatible with all devices. Sign documents, artwork, or personal notes
                        conveniently, wherever you are.
                    </p>

                    <h5>Key Features:</h5>

                    <p>
                        <ul>
                            <li>Customize your signature with different brush sizes and colors</li>
                            <li>User-friendly interface suitable for all skill levels</li>
                            <li>Compatible with both mouse and touchscreen devices</li>
                            <li>Save your digital signature for use across various documents</li>
                        </ul>

                        Experience the ease and artistry of our Online Signature Creator. Whether you're adding a
                        personal touch to your documents, signing agreements, or creating an artistic signature, our
                        tool has you covered.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default ImageEditor