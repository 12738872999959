import React, {useState} from "react";
import Page from "../../components/page";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {youtubeUrl} from '../../const/regex'
import '../../assets/styles/common.scss'
import {toast} from 'react-toastify';
import errorMessages from "../../const/errorMesages";
import {youTubeGetID} from "../../utils/commonFunc";
import {saveAs} from 'file-saver'

const YouTubeThumbnailDownloader = () => {
    const baseURL = 'https://img.youtube.com/vi/'

    const [url, setUrl] = useState(null)
    const [thumbnailUrl, setThumbnailUrl] = useState([])
    const [isFetched, setIsFetched] = useState(false)

    const onUrlHandler = () => {
        const isValid = youtubeUrl.test(url?.trim())
        if (isValid) {
            const videoId = youTubeGetID(url)
            setThumbnailUrl([
                {
                    url: `${baseURL}${videoId}/maxresdefault.jpg`,
                    name: 'Maximum Resolution'
                },
                {
                    url: `${baseURL}${videoId}/mqdefault.jpg`,
                    name: 'Medium Quality'
                },
                {
                    url: `${baseURL}${videoId}/0.jpg`,
                    name: 'Full Size'
                },
                {
                    url: `${baseURL}${videoId}/1.jpg`,
                    name: 'Thumbnail 1'
                },
                {
                    url: `${baseURL}${videoId}/2.jpg`,
                    name: 'Thumbnail 2'
                },
                {
                    url: `${baseURL}${videoId}/3.jpg`,
                    name: 'Thumbnail 3'
                },
                {
                    url: `${baseURL}${videoId}/hqdefault.jpg`,
                    name: 'High Quality'
                },
                {
                    url: `${baseURL}${videoId}/sddefault.jpg`,
                    name: 'Standard Definition'
                }
            ])
            setIsFetched(true)
        } else {
            toast.error(errorMessages.validUrl, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    const clear = () => {
        setIsFetched(false)
        setThumbnailUrl([])
        setUrl(null)
    }

    const onDownloadThumbnail = async (url) => {
        saveAs(url, "Twitter-logo");
    }

    return <Page id={'1'} title={'YouTube Thumbnail Downloader'}>
        <Row>
            <Col md={12}>
                {!isFetched ? <div className={'url-section'} align={'center'}>
                    <Label>Enter the link of a YouTube video below</Label>
                    <Input autoFocus onChange={(e) => setUrl(e.target.value)}
                           placeholder={'Copy & Past Any YouTube Video Link Here '}/>
                    <Button onClick={onUrlHandler} color={'primary'}>Fetch Thumbnail</Button>
                </div> : <div className={'preview-section'} align={'center'}>
                    <Button onClick={clear} className={'btn-fetch'} style={{marginBottom: 20}}>Fetch Another
                        Thumbnail</Button>
                    <Row>
                        {
                            thumbnailUrl.map((item, i) => <Col key={i} md={6} lg={3}>
                                <div className={'thumbnail-section'} align={'center'}>
                                    <Label>{item.name}</Label>
                                    <img src={item.url} alt={`youtube-thumbnail-${i + 1}`}/>
                                    <Button color={'success'}
                                            onClick={() => onDownloadThumbnail(item.url)}>Download</Button>
                                </div>
                            </Col>)
                        }
                    </Row>
                </div>}
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>Copy the link of the YouTube video you want a thumbnail from, then paste it
                        into
                        the designated box on our website. Click the "Fetch Thumbnail" button, and voilà! Our smart
                        system will
                        instantly fetch all the available thumbnails from that video.</p>

                    <p>
                        No more struggling to find the right image or resorting to blurry screenshots. Our Thumbnail
                        Downloader
                        gives you access to all the thumbnail options from the original video, saving you time and
                        effort.
                    </p>

                    <p>
                        Why are thumbnails so important for your YouTube success? Well, think of them as the face of
                        your video
                        – the first impression viewers get. With a captivating thumbnail, you can entice more people to
                        click
                        and watch your content, ultimately growing your audience and views.
                    </p>

                    <p>
                        Whether you're a seasoned content creator or just starting, having compelling thumbnails is a
                        game-changer. Our Thumbnail Downloader ensures you have the right visuals to make your videos
                        stand out
                        and shine.
                    </p>

                    <p>
                        Try it now and see the difference it makes! Paste the YouTube video link, click "Fetch
                        Thumbnail," and
                        explore all the available thumbnails at your fingertips. Elevate your YouTube presence with our
                        user-friendly and efficient Thumbnail Downloader. Happy downloading!
                    </p>

                    <br/>
                    <div>
                        <h5>Video tutorial</h5>
                        <iframe src="https://www.youtube.com/embed/4GC7lQuia3A"
                                title="How to download youtube thumbnails from toolprefix" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                                className={'youtube-video'}
                        ></iframe>
                    </div>
                </div>
            </Col>
        </Row>
    </Page>
}

export default YouTubeThumbnailDownloader