import {CONST} from '../const/const'
import {toast} from "react-toastify";
import errorMessages from "../const/errorMesages";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";
import * as htmlToImage from "html-to-image";
import {v4 as uuidv4} from 'uuid';

export const youTubeGetID = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}

export const addToFavorite = (id) => {
    let res = {status: false, type: null}
    const storedList = localStorage.getItem(CONST.FAVORITE_LIST)
    if (storedList) {
        const tempList = JSON.parse(storedList)
        if (tempList.includes(id)) {
            const index = tempList.indexOf(id);
            if (index !== -1) tempList.splice(index, 1)
            res = {status: true, type: CONST.REMOVED}
            toast.success(errorMessages.toolRemovedSuccess, {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            tempList.push(id)
            res = {status: true, type: CONST.ADDED}
            toast.success(errorMessages.toolAddedSuccess, {
                position: toast.POSITION.TOP_CENTER
            })
        }

        localStorage.setItem(CONST.FAVORITE_LIST, JSON.stringify(tempList))
    } else {
        const tool = [id]
        localStorage.setItem(CONST.FAVORITE_LIST, JSON.stringify(tool))
        res = {status: true, type: CONST.ADDED}
        toast.success(errorMessages.toolAddedSuccess, {
            position: toast.POSITION.TOP_CENTER
        })
    }

    if (!res.status && res.type === null) toast.error(errorMessages.somethingWrong, {
        position: toast.POSITION.TOP_CENTER
    })

    return res
}

export const checkIsFavorite = (id) => {
    let res = false
    const storedList = localStorage.getItem(CONST.FAVORITE_LIST)
    if (storedList) {
        const tempList = JSON.parse(storedList)
        res = tempList.includes(id)
    }

    return res
}

export const getFavoriteList = () => {
    let res = []
    const storedList = localStorage.getItem(CONST.FAVORITE_LIST)
    if (storedList) {
        res = JSON.parse(storedList)
    }
    return res
}

export const downloadFile = (data) => {
    const linkSource = data.imageBase64;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = data.name + ' - toolprefix.com.' + data.extension;
    downloadLink.click();
}

export const getBase64 = file => {
    return new Promise(resolve => {
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

export const downloadCanvas = async (a, format, fileName) => {
    if (a.getContext) {
        const canvas = await html2canvas(a);
        const dataURL = canvas.toDataURL(format);
        downloadjs(dataURL, `${fileName.substring(0, 10)}... - ToolPrefix.com.png`, format);
    }
}

export const downloadElement = async (ref, fileName) => {
    const dataUrl = await htmlToImage.toPng(ref.current);

    // download image
    const link = document.createElement('a');
    link.download = `${fileName} - ToolPrefix.com.png`;
    link.href = dataUrl;
    link.click();
}

export const generateUniqueId = () => {
    const uuid = localStorage.getItem('UUID')
    if (uuid) {
        return uuid
    } else {
        const tempId = uuidv4()
        localStorage.setItem('UUID', tempId)
        return tempId
    }
}