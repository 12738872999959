import React, {useEffect, useState} from "react";
import Page from "../../components/page";
import {Alert, Button, Col, FormGroup, Input, Label, Row, UncontrolledTooltip} from "reactstrap";
import toast, {Toaster} from 'react-hot-toast';
import '../../assets/styles/memeGenerator.scss'
import {getIpInfo} from "../../services/memeGeneratorServices";
import icnCity from '../../assets/img/icons/icnCity.png'
import icnRegion from '../../assets/img/icons/icnRegion.png'
import icnCountry from '../../assets/img/icons/icnCountry.png'
import icnCoins from '../../assets/img/icons/icnCoins.png'
import icnOrganization from '../../assets/img/icons/icnOrganization.png'
import icnCall from '../../assets/img/icons/icnCall.png'
import icnPostal from '../../assets/img/icons/icnPostal.png'
import icnUtc from '../../assets/img/icons/icnUtc.png'
import icnPin from '../../assets/img/icons/icnPin.png'
import {ipRegex} from "../../const/regex";

const MemeGenerator = () => {
    const [data, setData] = useState(null)
    const [ip, setIp] = useState('')

    const onClipboardCopy = (data) => {
        navigator?.clipboard?.writeText(data)
        toast.success('Copied to clipboard')
    }

    // useEffect(() => {
    //     loadData()
    // }, [])

    const loadData = async () => {
        if (ip?.trim() !== '' && ipRegex.test(ip)) {
            const res = await getIpInfo(ip)
            if (res?.data?.success && res?.data?.data) {
                setData(res.data.data)
            }
        } else {
            toast.error('Enter valid ip address')
        }
    }

    return <Page id={'29'} title={'IP Info Finder'}>
        <Row>
            <Col md={{size: 4, offset: 4}} className={'mb-4'}>
                <FormGroup>
                    <Label>Enter IP Address</Label>
                    <Input placeholder={'192.168.1.1'} value={ip} onChange={e => setIp(e.target.value)}/>
                </FormGroup>

                <Button color={'success'} className={'w-100'} onClick={loadData}>Find</Button>

                {data &&
                    <div className={'mt-4'}>
                        <Alert color="info">
                            <div className={'mb-4'}>
                                <h5>IP Address : {data.ip}</h5>
                            </div>

                            <div>
                                {data?.city && <p><img src={icnCity} width={20}/> City : <span>{data.city}</span></p>}
                                {data?.region &&
                                    <p><img src={icnRegion} width={20}/> Region : <span>{data.region}</span></p>}
                                {data?.country_name && <p><img src={icnCountry} width={20}/> Country
                                    : <span>{data.country_name} ({data.country})</span></p>}
                                {data?.currency_name && <p><img src={icnCoins} width={20}/> Currency
                                    : <span>{data.currency_name} ({data.currency})</span></p>}
                                {data?.org &&
                                    <p><img src={icnOrganization} width={20}/> Organization : <span>{data.org}</span>
                                    </p>}
                                {data?.postal && <p><img src={icnPostal} width={20}/> Postal Code
                                    : <span>{data.postal}</span></p>}
                                {data?.country_calling_code && <p><img src={icnCall} width={20}/> Calling Code
                                    : <span>{data.country_calling_code}</span></p>}
                                {data?.utc_offset && <p><img src={icnUtc} width={20}/> UTC
                                    : <span>{data.utc_offset}</span></p>}

                                {(data?.latitude || data?.longitude) && <p><img src={icnPin} width={20}/> Location
                                    : <a target={'_blank'}
                                         href={`http://maps.google.com/maps?z=12&t=m&q=loc:${data.latitude}+${data.longitude}`}>{data.latitude}, {data.longitude}</a>
                                </p>}
                            </div>
                        </Alert>
                    </div>}
            </Col>

            <Col md={12}>
                <hr/>
                <div className={'mt-3'}>
                    <p>
                        Unlock the potential of the IP Information Finder, your go-to solution for extracting insightful
                        details from any given IP address. Whether you're an IT professional, a security enthusiast, or
                        simply intrigued by online data, our tool provides comprehensive information right at your
                        fingertips.
                    </p>
                    <h5>Here's how it works: </h5>
                    <p>
                        The IP Information Finder empowers you to unravel the secrets behind any IP address. Input the
                        address you wish to explore, and our tool instantly fetches valuable data, including geographic
                        location, internet service provider details, and more.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>In-depth information retrieval from any IP address</li>
                        <li>User-friendly interface for seamless navigation</li>
                        <li>Real-time insights into IP-based details</li>
                    </ul>

                    <p>
                        Explore the ease and efficiency of the IP Information Finder today! Enhance your understanding
                        of online data and access valuable information effortlessly. Take charge of your digital
                        investigations and unlock a wealth of insights with the IP Information Finder!
                    </p>
                </div>
            </Col>
        </Row>
    </Page>
}

export default MemeGenerator