import image from "../assets/img/icons/image.png";
import rs from "./routes";
import tag from "../assets/img/icons/tag.png";
import textEditor from "../assets/img/icons/text-editor.png";
import editImage from '../assets/img/icons/edit-image.png'
import textToBase64 from "../assets/img/icons/text-to-base64.png";
import base64ToText from '../assets/img/icons/text-file.png'
import qrGenerator from "../assets/img/icons/qr-code.png";
import textA from '../assets/img/icons/text-a.png'
import icnBarcode from '../assets/img/icons/barcode.png'
import icnWhatsapp from '../assets/img/icons/whatsapp.png'
import icnJwt from '../assets/img/icons/jwt-token.png'
import icnChart from '../assets/img/icons/charts.png'
import icnWebCam from '../assets/img/icons/web-camera.png'
import icnMic from '../assets/img/icons/mic.png'
import icnColor from '../assets/img/icons/color-picker.png'
import icnFreePik from '../assets/img/icons/freepik.png'
import icnShare from '../assets/img/icons/icnShare.png'
import icnClock from '../assets/img/icons/icnClock.png'
import icnSeconds from '../assets/img/icons/icnSeconds.png'
import icnNumber from '../assets/img/icons/icnNumber.png'
import bCrypt from '../assets/img/icons/bcrypt.png'
import string from '../const/strings'
import icnSignature from '../assets/img/icons/icnSignature.png'
import icnFiverr from '../assets/img/icons/icnFiverr.png'
import icnMeme from '../assets/img/icons/icnMeme.png'
import icnIp from '../assets/img/icons/icnIp.png'

export const CONST = {
    FAVORITE_LIST: 'FAVORITE_LIST',
    ADDED: 'ADDED',
    REMOVED: 'REMOVED',
}

export const CATEGORIES = {
    ALL: 'ALL',
    DOWNLOADER: 'DOWNLOADER',
    EXTRACTOR: 'EXTRACTOR',
    EDITOR: 'EDITOR',
    ENCODER: 'ENCODER & DECODER',
    GENERATOR: 'GENERATOR',
    TESTER: 'TESTER',
    CONVERTER: 'CONVERTER',
    FINDER: 'FINDER'
}

export const CATEGORIES_LIST = [CATEGORIES.ALL, CATEGORIES.DOWNLOADER, CATEGORIES.EXTRACTOR, CATEGORIES.EDITOR, CATEGORIES.ENCODER, CATEGORIES.GENERATOR, CATEGORIES.TESTER, CATEGORIES.CONVERTER, CATEGORIES.FINDER]

export const toolList = [
    {
        id: '29',
        image: icnIp,
        title: 'IP Info Finder',
        url: rs.ipInfoFinder,
        category: CATEGORIES.FINDER,
        isNew: true
    },
    {
        id: '28',
        image: icnMeme,
        title: 'Meme Downloader',
        url: rs.memeGenerator,
        category: CATEGORIES.DOWNLOADER,
        isNew: true
    },
    {
        id: '27',
        image: icnFiverr,
        title: 'Fiverr Keyword Finder',
        url: rs.fiverrKeywordFinder,
        category: CATEGORIES.GENERATOR
    },
    {
        id: '26',
        image: icnSignature,
        title: 'Signature Drawing Board',
        url: rs.signatureDrawing,
        category: CATEGORIES.EDITOR
    },
    {
        id: '1',
        image: image,
        title: 'YT Thumbnail Downloader',
        url: rs.youTubeThumbnailDownloader,
        desc: 'No more struggling to find the right image or resorting to blurry screenshots. Our Thumbnail Downloader ' +
            'gives you access to all the thumbnail options from the original video, saving you time and effort',
        category: CATEGORIES.DOWNLOADER
    },
    {
        id: '2',
        image: tag,
        title: 'YT Tag Extractor',
        url: rs.youTubeTagExtractor,
        desc: 'No more guessing which tags to use or spending hours researching keywords. Our Tag Extractor gives you a ' +
            'quick and comprehensive list of tags used by the video\'s uploader, ensuring you optimize your own content for better discoverability',
        category: CATEGORIES.EXTRACTOR
    },
    // {
    //     id: 3,
    //     image: video,
    //     title: 'YouTube Video Downloader'
    // },
    {
        id: '4',
        image: textEditor,
        title: 'Text Editor',
        url: rs.textEditor,
        desc: 'With just a few clicks, you can enhance your text with various formatting styles, including bold, italic, ' +
            'underline, and strikethrough, to make your content stand out. Organize your thoughts effortlessly by adding ' +
            'bullets and numbering to create structured lists. You can even insert images to complement your text and add visual appeal to your work',
        category: CATEGORIES.EDITOR
    },
    // {
    //     id: 5,
    //     image: htmlEditor,
    //     title: 'HTML Editor'
    // },
    // {
    //     id: 6,
    //     image: emailEditor,
    //     title: 'Email Editor',
    //     url: rs.emailEditor
    // },
    // {
    //     id: 7,
    //     image: json,
    //     title: 'JSON Formatter',
    //     url: rs.jsonFormatter
    // },
    {
        id: '8',
        image: editImage,
        title: 'Image Editor',
        url: rs.imageEditor,
        desc: 'One of the standout features of our Image Editor is its intuitive interface, making it suitable for both ' +
            'beginners and experienced users. You don\'t need to be a professional graphic designer to create impressive ' +
            'edits - our Image Editor guides you through the process, step by step',
        category: CATEGORIES.EDITOR
    },
    {
        id: '9',
        image: textToBase64,
        title: 'Text to Base64',
        url: rs.textToBase64,
        desc: 'With a simple copy-paste mechanism, you can enter your text, word, or paragraph into the designated area, ' +
            'and our website will instantly encode it into Base64 format. Base64 is a widely used encoding method that ' +
            'converts binary data into a series of ASCII characters, making it unreadable to the naked eye. It\'s a great ' +
            'way to obscure and secure your information while maintaining its integrity',
        category: CATEGORIES.ENCODER
    },
    {
        id: '10',
        image: base64ToText,
        title: 'Base64 to Text',
        url: rs.base64ToText,
        desc: 'Whether you\'re a developer, data analyst, or simply someone in need of decoding Base64 data, our website ' +
            'offers a seamless and efficient solution. Simply paste the Base64 encoded string into the designated area, ' +
            'and our website will instantly convert it back into readable text, making it accessible and understandable',
        category: CATEGORIES.ENCODER
    },
    {
        id: '11',
        image: qrGenerator,
        title: 'QR Generator',
        url: rs.qrGenerator,
        desc: 'Whether you\'re a business owner, marketer, or simply someone looking to share information conveniently, ' +
            'our website offers a seamless and efficient solution. Simply enter your text, link, or paragraph into the ' +
            'designated area, and with a single click of the "Generate" button, your personalized QR code will be ready for use',
        category: CATEGORIES.GENERATOR
    },
    {
        id: '12',
        image: textA,
        title: 'String Repeater',
        url: rs.stringRepeater,
        desc: 'The process is as simple as typing in your text, choosing the repeat count, and customizing the separator - ' +
            'with a click of a button, your customized repeated string is generated and ready for use',
        category: CATEGORIES.GENERATOR
    },
    {
        id: '13',
        image: icnBarcode,
        title: 'Barcode Generator',
        url: rs.barcodeGenerator,
        desc: 'You don\'t need to be a barcode expert or install any software to use it - just type, click, and your ' +
            'customized barcode is generated instantly!',
        category: CATEGORIES.GENERATOR
    },
    {
        id: '14',
        image: icnWhatsapp,
        title: 'Whatsapp Link Generator',
        url: rs.whatsappLinkGenerator,
        desc: 'No need to go through the hassle of saving numbers or searching through contacts - simply type, click, ' +
            'and your WhatsApp message is ready to be sent',
        category: CATEGORIES.GENERATOR
    },
    {
        id: '15',
        image: icnJwt,
        title: 'JWT Decoder',
        url: rs.jwtDecoder,
        desc: 'You don\'t need to be a JWT expert or install any software - just paste the token, and the decoded ' +
            'information will be at your fingertips',
        category: CATEGORIES.ENCODER
    },
    {
        id: '16',
        image: icnChart,
        title: 'Charts Generator',
        url: rs.chartGenerator,
        desc: 'Enter your data, choose your desired chart type, and customize the color scheme to match your brand or ' +
            'personal style. With real-time previews, you can see your chart come to life as you make changes',
        category: CATEGORIES.GENERATOR
    },
    {
        id: '17',
        image: icnWebCam,
        title: 'WebCam Tester',
        url: rs.webCamTest,
        desc: 'With just one click, you can access the Webcam Tester and instantly see yourself on the screen, ensuring ' +
            'your webcam is up and running. No need to install any software or download applications - our website does all the work for you',
        category: CATEGORIES.TESTER
    },
    {
        id: '18',
        image: icnMic,
        title: 'Microphone Tester',
        url: rs.micTest,
        desc: 'With just one click, you can access the Microphone Tester and instantly start recording audio. Once you\'re ' +
            'done, the recorded audio is played back, allowing you to hear the quality of the microphone in real-time',
        category: CATEGORIES.TESTER
    },
    {
        id: '19',
        image: icnColor,
        title: 'Color Picker',
        url: rs.colorPicker,
        desc: 'You can select colors by dragging the cursor across the spectrum or by manually entering specific values. ' +
            'As you choose a color, the HEX, HSV, and RGB codes will be displayed in real-time, giving you a comprehensive ' +
            'understanding of the selected color\'s code representation',
        category: CATEGORIES.EXTRACTOR
    },
    {
        id: '20',
        image: icnFreePik,
        title: 'Freepik Image Downloader',
        url: rs.freePikImageDownloader,
        desc: 'Freepik offers premium images for download, and users must have a valid premium subscription or purchase ' +
            'the images individually to access them legally and use them without watermarks. This tool allows users to ' +
            'get freepik images without a watermark',
        category: CATEGORIES.DOWNLOADER
    },
    {
        id: '21',
        image: icnShare,
        title: 'Share Link Generator',
        url: rs.shareLinkGenerator,
        desc: 'You can enter the link or text you want to share, and our website will generate sharable links for email ' +
            'and popular social media platforms. Simply click on the respective sharing icons, and the link will be automatically ' +
            'copied to your clipboard, ready to be shared with your friends, colleagues, or followers',
        category: CATEGORIES.GENERATOR
    },
    {
        id: '22',
        image: icnSeconds,
        title: 'HH:MM:SS to Seconds',
        url: rs.hmsToSeconds,
        desc: 'You can enter a time in the format of hours, minutes, and seconds, and our website will instantly convert ' +
            'it into the equivalent value in seconds. No need for manual calculations or complex formulas - our tool ' +
            'does all the work for you',
        category: CATEGORIES.CONVERTER
    },
    {
        id: '23',
        image: icnClock,
        title: 'Seconds to HH:MM:SS',
        url: rs.secondsToHms,
        desc: 'You can enter a time in seconds, and our website will instantly convert it into the equivalent value in ' +
            'hours, minutes, and seconds. No need for manual calculations or complex formulas - our tool does all the work for you',
        category: CATEGORIES.CONVERTER
    },
    {
        id: '24',
        image: icnNumber,
        title: string.randomNumberGenerator,
        url: rs.randomNumber,
        desc: 'Whether you\'re a developer, statistician, or just someone in need of a random number for a game or ' +
            'experiment, our tool offers seamless and efficient random number generation',
        category: CATEGORIES.GENERATOR
    },
    {
        id: '25',
        image: bCrypt,
        title: 'Bcrypt',
        url: rs.bcrypt,
        desc: "Experience the peace of mind that comes from using a tried-and-true encryption method. Explore our Bcrypt" +
            " tool today and take control of your data's privacy like never before",
        category: CATEGORIES.ENCODER
    }
]

let _0xb416 = ["\x41\x49\x7A\x61\x53\x79\x43\x79\x47\x61\x6F\x73\x4D\x41\x45\x73\x49\x6D\x6E\x4D\x4D\x4C\x4D\x4C\x72\x59\x4E\x74\x75\x47\x79\x6A\x36\x4C\x5A\x53\x36\x69\x63"];
export const key = _0xb416[0]