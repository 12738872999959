import React, {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "reactstrap";
import './style.scss'
import Navbar from "../navbar";
import Footer from "../footer";
import rs from '../../const/routes'
import {Link, useParams} from "react-router-dom";
import {addToFavorite, checkIsFavorite} from "../../utils/commonFunc";
import {CONST} from "../../const/const";
import {
    WhatsappShareButton,
    WhatsappIcon,
    FacebookShareButton,
    FacebookIcon,
    InstapaperIcon,
    InstapaperShareButton,
    TelegramIcon,
    TelegramShareButton,
    EmailIcon,
    EmailShareButton,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    ViberIcon,
    ViberShareButton,
    PinterestShareButton,
    PinterestIcon,
    RedditIcon,
    RedditShareButton,
    TumblrIcon,
    TumblrShareButton
} from 'react-share'
import {motion} from "framer-motion";

const PagePath = ({children, ad, socialShare, id, title, navigator, footer}) => {
    const addToFavoriteHandler = () => {
        const res = addToFavorite(id)
        setIsFavorited(res.type === CONST.ADDED)
    }

    const [isFavorited, setIsFavorited] = useState(checkIsFavorite(id))

    const innerWidth = window.innerWidth
    const currentUrl = window.location.href

    const visible = {opacity: 1, y: 0, transition: {duration: 0.5}};

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <>
        <motion.article
            initial="hidden"
            animate="visible"
            exit={{opacity: 0, transition: {duration: 1}}}
            variants={{visible: {transition: {staggerChildren: 0.3}}}}
        >
            <Navbar/>
            <motion.div
                variants={{
                    hidden: {opacity: 0, y: -20},
                    visible
                }}
            >
                <Container style={{marginTop: 20}}>
                    {navigator !== false && <div className={'path-container'}>
                        <Link to={rs.home}>Home → </Link>
                        <span className={`path-last`}>{title}</span>
                    </div>}

                    <Row>
                        <Col md={12}>
                            <div className={'page-container'}>
                                <Row style={{
                                    marginBottom: 30,
                                    borderBottom: title ? '1px solid #E6E6E6' : 'none',
                                    paddingBottom: 10
                                }}>
                                    {title && <Col md={9} align={innerWidth > 768 ? 'left' : 'center'}>
                                        <h2>{title}</h2>
                                    </Col>}
                                    {/*{(id !== 0 && id !== undefined) &&*/}
                                    {/*    <Col md={3} align={innerWidth > 768 ? 'right' : 'center'}*/}
                                    {/*         style={{margin: '7px 0'}}>*/}
                                    {/*        <Button size={'sm'} color={isFavorited ? 'danger' : 'warning'}*/}
                                    {/*                onClick={addToFavoriteHandler}>{isFavorited ? 'Remove from Favorite' : 'Add to Favorite'}</Button>*/}
                                    {/*    </Col>}*/}
                                </Row>
                                {children}
                            </div>
                        </Col>
                        {/*<Col md={4}>*/}
                        {/*    <div className={'page-container'}>*/}
                        {/*        {ad}*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>

                    {socialShare === undefined && <Row>
                        <Col md={12}>
                            <div className={'share-section'} align={'center'}>
                                <h5>Share this page</h5>
                                <FacebookShareButton url={currentUrl}
                                                     quote={title}>
                                    <FacebookIcon size={30}/>
                                </FacebookShareButton>

                                <WhatsappShareButton url={currentUrl}
                                                     quote={title}>
                                    <WhatsappIcon size={30}/>
                                </WhatsappShareButton>

                                <InstapaperShareButton url={currentUrl}
                                                       quote={title}>
                                    <InstapaperIcon size={30}/>
                                </InstapaperShareButton>

                                <TelegramShareButton url={currentUrl}
                                                     quote={title}>
                                    <TelegramIcon size={30}/>
                                </TelegramShareButton>

                                <EmailShareButton url={currentUrl}
                                                  quote={title}>
                                    <EmailIcon size={30}/>
                                </EmailShareButton>

                                <FacebookMessengerShareButton url={currentUrl}
                                                              quote={title}>
                                    <FacebookMessengerIcon size={30}/>
                                </FacebookMessengerShareButton>

                                <LinkedinShareButton url={currentUrl}
                                                     quote={title}>
                                    <LinkedinIcon size={30}/>
                                </LinkedinShareButton>

                                <ViberShareButton url={currentUrl}
                                                  quote={title}>
                                    <ViberIcon size={30}/>
                                </ViberShareButton>

                                <PinterestShareButton url={currentUrl}
                                                      quote={title}>
                                    <PinterestIcon size={30}/>
                                </PinterestShareButton>

                                <RedditShareButton url={currentUrl}
                                                   quote={title}>
                                    <RedditIcon size={30}/>
                                </RedditShareButton>

                                <TumblrShareButton url={currentUrl}
                                                   quote={title}>
                                    <TumblrIcon size={30}/>
                                </TumblrShareButton>
                            </div>
                        </Col>
                    </Row>}
                </Container>
                {footer !== false && <Footer/>}
            </motion.div>
        </motion.article>
    </>
}

export default PagePath