import React, {useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, Input, Row} from "reactstrap";
import {encode as base64_encode} from 'base-64';
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";

const TextToBase64 = (props) => {
    const [data, setData] = useState('')
    const [typeText, setTypeText] = useState('')

    const onEncode = () => {
        const isCorrect = typeText.trim() !== ''
        if (isCorrect) {
            setTypeText(typeText)
            const encoded = base64_encode(typeText)
            setData(encoded)
        } else {
            toast.error(errorMessages.validText, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    const isDisabled = typeText.trim().length === 0

    return (<Page id={'9'} title={'Text to Base64'}>
        <Row>
            <Col md={6} className={'mb-4'}>
                <h5>Your Text</h5>
                <Input type={'textarea'} className={'height-400'}
                       onChange={(e) => setTypeText(e.target.value)}
                       value={typeText}/>

                <Button disabled={isDisabled} color={'success'} onClick={onEncode} className={'mt-3'}
                        style={{width: '100%'}}>Encode</Button>
                <Button color={'primary'} className={'mt-2 btn-clipboard'} disabled={isDisabled}
                        style={{width: '100%'}} onClick={() => {
                    navigator?.clipboard?.writeText(data)
                    toast.success(errorMessages.clipboardSuccess, {
                        position: toast.POSITION.TOP_CENTER
                    })
                }}>Copy to Clipboard</Button>
                <Button disabled={isDisabled} className={'mt-2'} style={{width: '100%'}} onClick={() => {
                    setData('')
                    setTypeText('')
                }}>Clear</Button>
            </Col>

            <Col md={6} className={'mb-4'}>
                <h5>Encoded Text</h5>
                <Input type={'textarea'} style={{height: 545}} value={data} readOnly/>
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Converting plain text into Base64 can be a cumbersome process, but with our user-friendly
                        interface,
                        you can achieve it effortlessly. Whether you're a developer, security-conscious user, or just
                        someone in need of securely encoding sensitive information, our Text to Base64 website is the
                        perfect solution.
                    </p>

                    <p>
                        With a simple copy-paste mechanism, you can enter your text, word, or paragraph into the
                        designated
                        area, and our website will instantly encode it into Base64 format. Base64 is a widely used
                        encoding
                        method that converts binary data into a series of ASCII characters, making it unreadable to the
                        naked eye. It's a great way to obscure and secure your information while maintaining its
                        integrity.
                    </p>

                    <p>
                        Our website ensures that the encoding process is quick, reliable, and hassle-free. You don't
                        need to
                        install any software or have advanced technical knowledge to use it - simply paste your text,
                        and
                        voila! Your encoded Base64 string is ready for use.
                    </p>

                    <p>
                        Whether you're looking to securely share sensitive information, pass data in URLs, or integrate
                        with
                        various applications, our Online Text to Base64 website has you covered.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Quick and easy text encoding to Base64</li>
                        <li>No installation or technical knowledge required</li>
                        <li>Securely encode sensitive information</li>
                        <li>Hassle-free copy-paste mechanism</li>
                        <li>Reliable and efficient encoding process</li>
                    </ul>

                    <p>
                        Experience the convenience and security of our Online Text to Base64 website today! Safeguard
                        your
                        information and unlock the power of Base64 encoding effortlessly. Get started now and ensure the
                        confidentiality of your text with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default TextToBase64