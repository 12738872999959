import React, {useEffect, useState} from "react";
import './style.scss'
import logo from '../../assets/img/logo-white.png'
import {Button, Col, Container, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";
import rs from '../../const/routes'

const Footer = () => {
    const currentYear = new Date().getFullYear()
    const copyrightYear = `2023 ${currentYear.toString() !== '2023' ? `- ${currentYear}` : ''}`
    const innerWidth = window.innerWidth
    const [cookies, setCookies] = useState(localStorage.getItem('COOKIES'))
    return <>
        <div className={'footer-container'} align={'center'}>
            <Container>
                <Row>
                    <Col md={12}>
                        <img src={logo} alt="tool-prefix-logo" className={'img-footer'}/>
                    </Col>

                    <Col md={12}>
                        <p className={'lbl-desc'}>ToolPrefix is a free online tools provider, where you can use any
                            online
                            tools without paying charges.</p>
                    </Col>

                    <div className={'footer-nav'}>
                        <Link to={rs.home}>Home</Link>
                        <Link to={rs.aboutUs} state={{id: 0, title: 'About Us'}}>About Us</Link>
                        <Link to={rs.privacyPolicy} state={{id: 0, title: 'Privacy Policy'}}>Privacy Policy</Link>
                        <Link to={rs.disclaimer} state={{id: 0, title: 'Disclaimer'}}>Disclaimer</Link>
                        <Link to={rs.contactUs} state={{id: 0, title: 'Contact Us'}}>Contact Us</Link>
                    </div>

                    <Col md={12} className={'mt-4'}>
                        <a href="//www.dmca.com/Protection/Status.aspx?ID=9a39bfb6-f536-40ec-81ae-410e4f22aee3"
                           title="DMCA.com Protection Status" className="dmca-badge"> <img
                            src="https://images.dmca.com/Badges/dmca_protected_sml_120l.png?ID=9a39bfb6-f536-40ec-81ae-410e4f22aee3"
                            alt="DMCA.com Protection Status" width={100}/></a>
                        <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
                    </Col>
                </Row>
            </Container>

            <div className={'footer-bottom'}>
                <Container>
                    <Row style={{justifyContent: 'space-between'}}>
                        <Col md={6} align={innerWidth > 768 ? 'left' : 'center'}>
                            <p className={'credits'}>© {copyrightYear} <b>ToolPrefix</b></p>
                        </Col>

                        <Col md={6} align={innerWidth > 768 ? 'right' : 'center'}>
                            <p className={'credits'}>Developed by <a href="http://scodelabs.dev"
                                                                     target={'_blank'}>sCodeLabs</a></p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        {(cookies === null || cookies === undefined || localStorage.getItem('COOKIES') === null) &&
            <div className={'cookie-notification'} align={'center'}>
                <Label>This website uses cookies to ensure you get the best experience on our website <Link
                    to={rs.useOfCookies} state={{id: 0, title: 'Use of Cookies'}}>More info</Link></Label>
                <Button color={'primary'} onClick={() => {
                    localStorage.setItem('COOKIES', 'true')
                    window.location.reload()
                }
                }>Got it!</Button>
            </div>}
    </>
}

export default Footer