import React, {useEffect, useState} from "react";
import Page from "../../components/page";
import {Alert, Button, Col, FormGroup, Input, Label, Row, UncontrolledTooltip} from "reactstrap";
import toast, {Toaster} from 'react-hot-toast';
import '../../assets/styles/memeGenerator.scss'
import {getMemes} from "../../services/memeGeneratorServices";
import {Gallery} from "react-grid-gallery";
import {isMobile} from 'react-device-detect';

const MemeGenerator = () => {
    const [data, setData] = useState([])

    const onClipboardCopy = (data) => {
        navigator?.clipboard?.writeText(data)
        toast.success('Copied to clipboard')
    }

    useEffect(() => {
        loadMemes()
    }, [])

    const loadMemes = async () => {
        const res = await getMemes()
        if (res?.data?.success && res?.data?.data?.memes) {
            setData(res.data.data.memes.map(item => {
                return {
                    src: item.url,
                    width: '100%',
                    height: 174,
                    customOverlay: (
                        <div className="overlay-menu">
                            <p>{item.name}</p>
                            <Button size={'sm'} color={'primary'} style={{cursor: 'pointer'}}>Download</Button>
                        </div>
                    ),
                }
            }))
        }
    }

    const downloadImage = (index) => {
        let row = data[index]
        var link = document.createElement('a');
        link.href = row.src;
        link.download = row.src;
        link.target = '_blank'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return <Page id={'28'} title={'Meme Downloader'}>
        <Row>
            <Col md={12} className={'mb-4'} align={'center'}>
                {data.length > 0 && <Gallery images={data} enableImageSelection={false} onClick={downloadImage}
                                             thumbnailStyle={{width: isMobile ? '100%' : 300}}/>}
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <p>
                        Dive into a plethora of trending memes that are guaranteed to bring laughter to your online
                        presence. Whether you're a content creator, social media enthusiast, or just looking to spice up
                        your posts, our tool is the ultimate meme companion you've been searching for.
                    </p>
                    <h5>Here's how it works: </h5>
                    <p>
                        The Meme Generator offers an endless array of trending memes, right at your fingertips. With a
                        simple click, explore and download the latest and most popular memes circulating across various
                        platforms.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Instant access to a collection of trending and hilarious memes</li>
                        <li>Effortless download and sharing options</li>
                        <li>Stay up-to-date with the latest meme trends</li>
                    </ul>

                    <p>
                        Embrace the fun and creativity of the Meme Generator today! Elevate your social media game and
                        infuse your content with humor and relatability. Get ready to join the meme culture and amplify
                        your online presence with the Meme Generator!
                    </p>
                </div>
            </Col>
        </Row>
    </Page>
}

export default MemeGenerator