import React, {useRef, useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss';
import '../../assets/styles/barcodeGenerator.scss';
import {Button, Col, Input, Row} from "reactstrap";
import {downloadElement} from "../../utils/commonFunc";
import Barcode from 'react-barcode';
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";

const BarcodeGenerator = (props) => {
    const [typeText, setTypeText] = useState('')
    const [data, setData] = useState('')
    const domEl = useRef(null);

    const downloadBarcode = async () => {
        await downloadElement(domEl, typeText)
    }

    const generateBarcode = () => {
        if (typeText.trim().length > 40) {
            toast.error(errorMessages.character50Error, {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            if (typeText.trim() === '') {
                toast.error(errorMessages.validText, {
                    position: toast.POSITION.TOP_CENTER
                })
            } else {
                setData(typeText)
            }
        }
    }

    const isDisabled = typeText.trim().length === 0

    return (<Page id={'13'} title={'Barcode Generator'}>
        <Row>
            <Col md={6} className={'mb-4'}>
                <h5>Your Text</h5>
                <Input type={'textarea'} className={'height-250'}
                       onChange={(e) => setTypeText(e.target.value)}
                       value={typeText} maxLength={40}/>
                <div align={'right'}>{typeText.length} / 40</div>

                <Button disabled={isDisabled} color={'success'} className={'mt-3'} style={{width: '100%'}}
                        onClick={generateBarcode}>Generate</Button>
                <Button disabled={isDisabled} className={'mt-2'} style={{width: '100%'}} onClick={() => {
                    setTypeText('')
                    setData('')
                }}>Clear</Button>
            </Col>

            <Col md={6} className={'mb-4'}>
                <h5>Generated Barcode</h5>
                <div className={'barcode-container'} align={'center'}>
                    <div ref={domEl}>
                        <label style={{color: '#fff'}}>toolprefix.com</label>
                        <Barcode
                            value={data === '' ? 'https://toolprefix.com' : data}
                        />
                    </div>

                    {data && <Button color={'primary'} className={'mt-2'} style={{width: '100%'}}
                                     onClick={downloadBarcode}>Download</Button>}
                </div>
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Barcodes have revolutionized the way we store and access information, making data easily
                        scannable
                        and shareable. With our user-friendly interface and character limit of 40, generating barcodes
                        is
                        quick and convenient.
                    </p>

                    <p>
                        Whether you're a small business owner, marketer, or simply someone looking to incorporate
                        barcodes
                        into your projects, our website offers a seamless and efficient solution. Simply enter your
                        desired
                        text or link into the designated area, keeping it within the 40-character limit, and with a
                        click of
                        the "Generate" button, your personalized barcode is ready for use.
                    </p>

                    <p>
                        Our Online Barcode Generator ensures a quick and reliable barcode generation process. You don't
                        need
                        to be a barcode expert or install any software to use it - just type, click, and your customized
                        barcode is generated instantly!
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Effortless barcode generation from text or links</li>
                        <li>Maximum character length of 40 for concise barcodes</li>
                        <li>No installation or technical expertise required</li>
                        <li>Quick and user-friendly interface</li>
                        <li>Reliable and efficient barcode generation process</li>
                    </ul>

                    <p>
                        Experience the convenience and power of our Online Barcode Generator today! Create custom
                        barcodes
                        for text or links with ease, ensuring easy data sharing and scanning. Get started now and
                        discover
                        the endless possibilities of barcodes with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default BarcodeGenerator