import React, {useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, Input, Label, Row} from "reactstrap";
import {decode as base64_decode} from 'base-64';
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";
import {base64} from "../../const/regex";

const Base64ToText = (props) => {
    const [data, setData] = useState('')
    const [typeText, setTypeText] = useState('')

    const onDecode = () => {
        const isCorrect = base64.test(typeText)
        if (isCorrect) {
            const decoded = base64_decode(typeText)
            setData(decoded)
        } else {
            toast.error(errorMessages.validBase64, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    const isDisabled = typeText.trim().length === 0
    return (<Page id={'10'} title={'Base64 to Text'}>
        <Row>
            <Col md={6} className={'mb-4'}>
                <h5>Base64 Text</h5>
                <Input type={'textarea'} className={'height-400'}
                       onChange={(e) => setTypeText(e.target.value)}
                       value={typeText}/>

                <Button disabled={isDisabled} color={'success'} onClick={onDecode} className={'mt-3'}
                        style={{width: '100%'}}>Decode</Button>
                <Button disabled={isDisabled} color={'primary'} className={'mt-2 btn-clipboard'} style={{width: '100%'}}
                        onClick={() => {
                            navigator?.clipboard?.writeText(data)
                            toast.success(errorMessages.clipboardSuccess, {
                                position: toast.POSITION.TOP_CENTER
                            })
                        }}>Copy to Clipboard</Button>
                <Button disabled={isDisabled} className={'mt-2'} style={{width: '100%'}} onClick={() => {
                    setData('')
                    setTypeText('')
                }}>Clear</Button>
            </Col>

            <Col md={6} className={'mb-4'}>
                <h5>Decoded Text</h5>
                <Input type={'textarea'} style={{height: 545}} value={data} readOnly/>
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Base64 encoding is commonly used to represent binary data in a human-readable format. However,
                        decoding
                        Base64 strings manually can be challenging and time-consuming. With our user-friendly interface,
                        you can now
                        easily decode Base64 and retrieve the original text with just a few clicks!
                    </p>

                    <p>
                        Whether you're a developer, data analyst, or simply someone in need of decoding Base64 data, our
                        website
                        offers a seamless and efficient solution. Simply paste the Base64 encoded string into the
                        designated area,
                        and our website will instantly convert it back into readable text, making it accessible and
                        understandable.
                    </p>

                    <p>
                        Our Online Base64 to Text website ensures a quick and reliable decoding process. You don't need
                        to be an
                        expert in Base64 decoding or install any software to use it - simply paste the encoded text, and
                        watch it
                        transform into its original form.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>
                    <ul>
                        <li>Quick and easy Base64 decoding to readable text</li>
                        <li>No installation or technical expertise required</li>
                        <li>Effortless copy-paste mechanism</li>
                        <li>Reliable and efficient decoding process</li>
                    </ul>

                    <p>
                        Experience the convenience and power of our Online Base64 to Text website today! Decode Base64
                        strings with
                        ease and retrieve the original text in seconds. Get started now and unlock the hidden content of
                        Base64
                        encoded data with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default Base64ToText