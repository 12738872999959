import React, {useState} from "react";
import Page from "../../components/page";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {timeRegex} from "../../const/regex";
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";
import moment from "moment";

const HmsToSeconds = () => {
    const [time, setTime] = useState(moment.duration(moment().format('HH:mm:ss')).asSeconds())
    const [seconds, setSeconds] = useState('')

    const convertTime = () => {
        if (time !== '') {
            const second = moment.utc(time * 1000).format('HH:mm:ss')
            setSeconds(second)
        } else {
            toast.error(errorMessages.validTime, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    return <Page id={'23'} title={'Seconds to HH:MM:SS'}>
        <Row>
            <Col md={{size: 4, offset: 4}} align={'center'}>
                <h5>Enter seconds</h5>
                <Input type={'number'} placeholder={'12:59:00'} value={time} style={{textAlign: 'center'}}
                       onChange={(e) => setTime(e.target.value)}/>
                <Button style={{marginTop: 10, width: '100%', marginBottom: 20}} color={'success'}
                        onClick={convertTime}>Convert</Button>

                {seconds && <>
                    <h5>HH:mm:ss Format</h5>
                    <Label>{seconds}</Label>
                </>}
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Whether you're a developer, time tracker, or simply someone looking to convert time from seconds
                        to
                        a more human-readable format, our user-friendly interface makes time conversion a breeze.
                    </p>

                    <p>
                        With our Seconds to HH:MM:SS Converter, you can enter a time in seconds, and our website will
                        instantly convert it into the equivalent value in hours, minutes, and seconds. No need for
                        manual
                        calculations or complex formulas - our tool does all the work for you!
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Effortless time conversion from seconds to HH:MM:SS format</li>
                        <li>Quick and user-friendly interface</li>
                        <li>Instant conversion with real-time results</li>
                    </ul>

                    <p>
                        Experience the convenience and efficiency of our Online Seconds to HH:MM:SS Converter today!
                        Perform
                        time conversions with ease and accuracy, making time calculations hassle-free. Get started now
                        and
                        unlock the power of time conversion with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>
}

export default HmsToSeconds