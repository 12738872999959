import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from 'react-toastify';
import 'react-phone-number-input/style.css'
import {Toaster} from "react-hot-toast";
import Loader from "./components/loader";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Loader/>
        <ToastContainer/>
        <Toaster/>
        <App/>
    </React.StrictMode>
);
reportWebVitals();
