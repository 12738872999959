import React, {useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, Input, Row} from "reactstrap";
import {encode as base64_encode} from 'base-64';
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";
import bcrypt from 'bcryptjs'

const TextToBase64 = (props) => {
    const [data, setData] = useState('')
    const [typeText, setTypeText] = useState('')
    const [typeHash, setTypeHash] = useState('')
    const [typeString, setTypeString] = useState('')

    const onEncode = () => {
        const isCorrect = typeText.trim() !== ''
        if (isCorrect) {
            const salt = bcrypt.genSaltSync(10)
            const res = bcrypt.hashSync(typeText, salt)
            setTypeText(typeText)
            setData(res)

        } else {
            toast.error(errorMessages.validText, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    const checkHash = () => {
        const isHash = typeHash.trim() !== ''
        const isString = typeString.trim() !== ''

        if (isHash && isString) {
            bcrypt.compare(typeString, typeHash, (err, result) => {
                if (result) {
                    toast.success(errorMessages.bcryptMatch, {
                        position: toast.POSITION.TOP_CENTER
                    })
                } else {
                    toast.error(errorMessages.bcryptNotMatch, {
                        position: toast.POSITION.TOP_CENTER
                    })
                }
            })
        }
    }

    const isDisabled = typeText.trim().length === 0
    const isHashDisabled = (typeHash.trim().length === 0 && typeString.trim().length === 0)

    return (<Page id={'9'} title={'Bcrypt'}>
        <Row>
            <Col md={6} className={'br-md-1'} style={{paddingRight: 20}}>
                <h3 className={'bb-1 pb-2 mb-3'}>Encrypt</h3>
                <Row>
                    <Col md={8} className={'mb-4'}>
                        <h5>Your Text</h5>
                        <Input type={'textarea'} style={{height: 128}}
                               onChange={(e) => setTypeText(e.target.value)}
                               value={typeText}/>
                    </Col>

                    <Col md={4} className={'pt-3 mb-3'}>
                        <Button disabled={isDisabled} color={'success'} onClick={onEncode} className={'mt-3'}
                                style={{width: '100%'}}>Encrypt</Button>
                        <Button color={'primary'} className={'mt-2 btn-clipboard'} disabled={isDisabled}
                                style={{width: '100%'}} onClick={() => {
                            navigator?.clipboard?.writeText(data)
                            toast.success(errorMessages.clipboardSuccess, {
                                position: toast.POSITION.TOP_CENTER
                            })
                        }}>Copy to Clipboard</Button>
                        <Button disabled={isDisabled} className={'mt-2'} style={{width: '100%'}} onClick={() => {
                            setData('')
                            setTypeText('')
                        }}>Clear</Button>
                    </Col>

                    <Col md={12} className={'mb-4'}>
                        <h5>Encrypted Text</h5>
                        <Input type={'textarea'} style={{height: 50}} value={data} readOnly/>
                    </Col>
                </Row>
            </Col>

            <Col md={6} style={{paddingLeft: 20}}>
                <h3 className={'bb-1 pb-2 mb-3'}>Decrypt</h3>
                <Row>
                    <Col md={12} className={'mb-4'}>
                        <h5>Hash to Check</h5>
                        <Input type={'textarea'} style={{height: 50}}
                               onChange={(e) => setTypeHash(e.target.value)}
                               value={typeHash}/>
                    </Col>

                    <Col md={12} className={'mb-1'}>
                        <h5>String to Check</h5>
                        <Input type={'textarea'} style={{height: 50}} value={typeString}
                               onChange={(e) => setTypeString(e.target.value)}/>
                    </Col>

                    <Col md={12} className={'pt-3 d-flex justify-content-between'}>
                        <Button disabled={isHashDisabled} color={'success'} onClick={checkHash}
                                className={'mt-2 mr-md-1'} style={{width: '100%'}}>Check</Button>

                        <Button disabled={isHashDisabled} className={'mt-2'} style={{width: '100%'}} onClick={() => {
                            setTypeString('')
                            setTypeHash('')
                        }}>Clear</Button>
                    </Col>
                </Row>
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        <b>Encrypt with Bcrypt</b>
                        <br/>
                        Encrypting your data has never been easier. Simply enter the text you want to secure, and our
                        Bcrypt tool will transform it into a robust and irreversible hash. This ensures that even if
                        someone gains access to the hash, they won't be able to reverse-engineer the original text.
                    </p>

                    <p>
                        <b>Decrypt and Compare</b>
                        <br/>
                        In addition to encryption, our platform offers the unique capability to decrypt Bcrypt hashes.
                        If you possess a Bcrypt hash and need to verify its content, our tool lets you input the hash
                        alongside the original text. By comparing the decrypted result with your provided text, you can
                        ensure their authenticity.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Top-Notch Security</li>
                        <li>User-Friendly Interface</li>
                        <li>Immediate Results</li>
                        <li>Cross-Platform Compatibility</li>
                        <li>No Data Storage</li>
                        <li>Educational Resource</li>
                        <li>Versatile Use Cases</li>
                    </ul>

                    <p>
                        Experience the peace of mind that comes from using a tried-and-true encryption method. Explore
                        our Bcrypt tool today and take control of your data's privacy like never before.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default TextToBase64