export default {
    home: '/',
    aboutUs: '/about-us',
    privacyPolicy: '/privacy-policy',
    disclaimer: '/disclaimer',
    contactUs: '/contact-us',
    youTubeThumbnailDownloader: '/youtube-thumbnail-downloader',
    youTubeTagExtractor: '/youtube-tag-extractor',
    textEditor: '/text-editor',
    emailEditor: '/email-editor',
    jsonFormatter: '/json-formatter',
    imageEditor: '/image-editor',
    textToBase64: '/text-to-base64',
    bcrypt: '/text-to-bcrypt',
    base64ToText: '/base64-to-text',
    qrGenerator: '/qr-generator',
    stringRepeater: '/string-repeater',
    barcodeGenerator: '/barcode-generator',
    whatsappLinkGenerator: '/whatsapp-link-generator',
    jwtDecoder: '/jwt-token-decoder',
    chartGenerator: '/chart-generator',
    webCamTest: '/web-camera-tester',
    micTest: '/microphone-tester',
    colorPicker: '/color-picker',
    freePikImageDownloader: '/freepik-image-downloader',
    shareLinkGenerator: '/share-link-generator',
    hmsToSeconds: '/hh-mm-ss-to-seconds',
    secondsToHms: '/seconds-to-hh-mm-ss',
    useOfCookies: '/use-of-cookies',
    randomNumber: '/random-number-generator',
    signatureDrawing: '/signature-drawing-board',
    fiverrKeywordFinder: '/fiverr-keyword-finder',
    memeGenerator: '/meme-generator',
    ipInfoFinder: '/ip-info-finder'
}