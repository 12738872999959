import React from "react";
import './style.scss'
import {Button, Container} from "reactstrap";
import logo from '../../assets/img/logo.png'
import {Star} from 'react-feather'
import {Link} from "react-router-dom";
import TopBanner from "../topBanner";

const Navbar = () => {
    return <>
        <div style={{height: 70}}>
            <div className={'navbar-container'}>
                <Container>
                    <Link to={'/'}>
                        <img className={'img-logo'} src={logo} alt="tool-prefix-logo"/>
                    </Link>

                    {/*<div>*/}
                    {/*    <Button color={'success'} style={{marginLeft: 20}}>Sign In</Button>*/}
                    {/*</div>*/}
                </Container>
            </div>
        </div>
    </>
}

export default Navbar