import React, {useState} from "react";
import Page from "../../components/page";
import {Alert, Button, Col, FormGroup, Input, Label, Row, UncontrolledTooltip} from "reactstrap";
import toast, {Toaster} from 'react-hot-toast';
import Select from "react-select";

const categories = [
    {
        label: 'Logo Design',
        value: 'LOGO_DESIGN',
        keywords: ['#1 Logo creation', '#2 Logo', '#3 Logo design', '#4 Graphic design logos', '#5 Business logo', '#6 Logo illustration', '#7 Logo and brand identity', '#8 Logo design business', '#9 Company logo', '#10 Vintage logo design']
    },
    {
        label: 'Brand Style Guides',
        value: 'BRAND_STYLE_GUIDES',
        keywords: ['#1 Brand style guide', '#2 Brand kit', '#3 Brand design', '#4 Brand guide', '#5 Brand', '#6 Brand guidelines', '#7 Brand book', '#8 Branding package'],
    },
    {
        label: 'Game Art - Character Design',
        value: 'GAME_ART_CHARACTER_DESIGN',
        keywords: ['#1 Minecraft skin', '#2 Pixel art animation', '#3 Sprite sheet', '#4 Sprite', '#5 Roblox clothing']
    },
    {
        label: 'Game Art - Props & Objects',
        value: 'GAME_ART_PROPS_OBJECTS',
        keywords: ['#1 Roblox map', '#2 Texture', '#3 Roblox model', '#4 Game assets', '#5 Minecraft texture pack']
    },
    {
        label: 'Game Art - Backgrounds & Environments',
        value: 'GAME_ART_BACKGROUNDS_ENVIRONMENTS',
        keywords: ['#1 Roblox GFX', '#2 Roblox map', '#3 VRChat world', '#4 3D environment', '#5 3D game environment']
    },
    {
        label: 'Game Art - UI & UX',
        value: 'GAME_ART_UI_UX',
        keywords: ['#1 Game UI', '#2 Roblox UI', '#3 Roblox GUI', '#4 Game UI design']
    },
    {
        label: 'Graphics For Streamers',
        value: 'GRAPHICS_FOR_STREAMERS',
        keywords: ['#1 Twitch emotes', '#2 Twitch overlay', '#3 Twitch badges', '#4 Fortnite thumbnail', '#5 Stream overlay', '#6 Discord emoji']
    },
    {
        label: 'Business Cards & Stationery',
        value: 'BUSINESS_CARDS_STATIONERY',
        keywords: ['#1 Business card design', '#2 Email signature', '#3 Logo and business card', '#4 Business card', '#5 QR code', '#6 Capability statement']
    },
    {
        label: 'Graphics Design - Website Design',
        value: 'GRAPHICS_DESIGN_WEBSITE_DESIGN',
        keywords: ['#1 Figma website design', '#2 Website design', '#3 Figma design', '#4 UI UX website design', '#5 Website', '#6 Website mockup', '#7 Website UI UX design', '#8 Website UI design', '#9 Design website', '#10 Figma']
    },
    {
        label: 'App Design',
        value: 'APP_DESIGN',
        keywords: ['#1 Mobile app design', '#2 App UI design', '#3 Mobile app UI design', '#4 App screenshots', '#5 Figma app design', '#6 App design', '#7 App UI UX design', '#8 Dashboard design', '#9 App prototype', '#10 UIUX']
    },
    {
        label: 'Landing Page Design',
        value: 'LANDING_PAGE_DESIGN',
        keywords: ['#1 Google Sites', '#2 Google Sites website', '#3 Figma landing page', '#4 Systeme.io', '#5 SaaS landing page']
    },
    {
        label: 'Resume Design',
        value: 'RESUME_DESIGN',
        keywords: ['#1 Resume design', '#2 Professional CV design', '#3 CV making', '#4 Resume template', '#5 Portfolio design']
    },
    {
        label: 'Illustration',
        value: 'ILLUSTRATION',
        keywords: ['#1 Map illustration', '#2 Illustration', '#3 Vector illustration', '#4 Medical illustration', '#5 Character art', '#6 Line drawing', '#7 Cartoon art', '#8 Website illustration', '#9 Illustration art', '#10 Line art', '#11 Anime art', '#12 Isometric', '#13 Sketch']
    },
    {
        label: "Children's Book Illustration",
        value: 'CHILDRENS_BOOK_ILLUSTRATION',
        keywords: ['#1 Children\'s book illustration', '#2 Book illustration', '#3 Children\'s book', '#4 Children illustration', '#5 Children\'s book', '#6 Kids story ebook illustration', '#7 Children\'s book illustrations', '#8 3D children\'s book illustration']
    },
    {
        label: 'AI Artists - Image Generation',
        value: 'AI_ARTISTS_IMAGE_GENERATION',
        keywords: ['#1 AI image generator', '#2 Midjourney', '#3 AI art', '#4 Midjourney AI', '#5 AI generated art', '#6 AI image creator', '#7 AI image', '#8 AI model', '#9 AI influencer']
    },
    {
        label: 'AI Artists - Custom Image Prompts',
        value: 'AI_ARTISTS_CUSTOM_IMAGE_PROMPTS',
        keywords: ['#1 Lora', '#2 Stable diffusion']
    },
    {
        label: 'NFT Art',
        value: 'NFT_ART',
        keywords: ['#1 NFT art', '#2 NFT collection', '#3 NFT pixel art', '#4 NFT', '#5 3D NFT']
    },
    {
        label: 'Pattern Design',
        value: 'PATTERN_DESIGN',
        keywords: ['#1 Pattern design', '#2 Seamless pattern design', '#3 Textile design', '#4 Seamless pattern', '#5 Fabric design', '#6 Pattern', '#7 Monogram pattern']
    },
    {
        label: 'Fonts & Typography',
        value: 'FONTS_TYPOGRAPHY',
        keywords: ['#1 Create font', '#2 Custom font', '#3 Font', '#4 Font design', '#5 Calligraphy']
    },
    {
        label: 'Poster Design',
        value: 'POSTER_DESIGN',
        keywords: ['#1 Movie poster', '#2 Film poster', '#3 Event poster', '#4 Concert poster', '#5 Vintage poster', '#6 Canvas design', '#7 Typography poster', '#8 Canvas wall art']
    },
    {
        label: 'Brochure Design',
        value: 'BROCHURE_DESIGN',
        keywords: ['#1 Brochure design', '#2 Company profile design', '#3 Brochure', '#4 White paper design', '#5 Company profile', '#6 Trifold brochure', '#7 Annual report', '#8 Trifold brochure design', '#9 Company brochure', '#10 Digital brochure', '#11 Booklet', '#12 Digital brochure', '#13 Proposal', '#14 Listing presentation', '#15 PDF brochure', '#16 Real estate brochure']
    },
    {
        label: 'Flyer Design',
        value: 'FLYER_DESIGN',
        keywords: ['#1 Flyer', '#2 Postcard design', '#3 Flyer design', '#4 Animated flyer', '#5 Magazine ad', '#6 Graphic design flyer', '#7 Media kit', '#8 Event flyer', '#9 Business flyer design', '#10 Motion flyer', '#11 Marketing material', '#12 Marketing flyer', '#13 Event flyer design', '#14 Digital flyer', '#15 Club flyer']
    },
    {
        label: 'Book Design - Book Cover Design',
        value: 'BOOK_DESIGN_BOOK_COVER_DESIGN',
        keywords: ['#1 Book cover design', '#2 eBook cover', '#3 Book cover illustration', '#4 eBook cover design', '#5 KDP book cover', '#6 Book cover art', '#7 Kindle book cover', '#8 Romance book cover']
    },
    {
        label: 'Book Layout Design & Typesetting',
        value: 'BOOK_LAYOUT_DESIGN_TYPESETTING',
        keywords: ['#1 eBook design', '#2 Book formatting', '#3 Book design', '#4 Children\'s book formatting', '#5 Canva eBook design', '#6 Canva eBook', '#7 Book layout', '#8 Book cover', '#9 Adobe InDesign', '#10 Book cover design', '#11 Typesetting', '#12 eBook cover']
    },
    {
        label: 'Album Cover Design',
        value: 'ALBUM_COVER_DESIGN',
        keywords: ['#1 Cover art', '#2 Album art', '#3 Album cover', '#4 Album cover art', '#5 Album cover design', '#6 Music cover art', '#7 Mixtape', '#8 CD cover', '#9 Song cover art', '#10 Music cover', '#11 Single cover', '#12 Cartoon album cover']
    },
    {
        label: 'Podcast Cover',
        value: 'PODCAST_COVER',
        keywords: ['#1 Podcast logo', '#2 Podcast cover art', '#3 Podcast cover', '#4 Podcast artwork', '#5 Podcast art', '#6 Podcast logo design', '#7 Podcast cover design']
    },
    {
        label: 'Packaging & Label Design - Packaging Design',
        value: 'PACKAGING_LABEL_DESIGN_PACKAGING_DESIGN',
        keywords: ['#1 Packaging design', '#2 Packaging', '#3 Product packaging design', '#4 Label design', '#5 Box design', '#6 Packaging box design', '#7 Product label', '#8 Amazon packaging design', '#9 Product packaging', '#10 Box packaging', '#11 Pouch design', '#12 Product box design', '#13 Cannabis packaging', '#14 Mylar bag', '#15 Label', '#16 Food packaging design', '#17 Product packaging box design', '#18 Mailer box', '#19 Cosmetic packaging design', '#20 Mailer box design']
    },
    {
        label: 'Packaging & Label Design - Label Design',
        value: 'PACKAGING_LABEL_DESIGN_LABEL_DESIGN',
        keywords: ['#1 Product label design', '#2 Label design', '#3 Product label', '#4 Supplement label design', '#5 Label', '#6 Bottle label design', '#7 Wine label', '#8 Supplement label', '#9 Clothing tags', '#10 Hang tag', '#11 Candle label design', '#12 Beer label', '#13 Gin label', '#14 Clothing label', '#15 Cosmetic label design', '#16 Cosmetic label', '#17 Nutrition facts', '#18 Nutrition facts label', '#19 Supplement facts']
    },
    {
        label: 'Storyboard',
        value: 'STORYBOARD',
        keywords: ['#1 Storyboard', '#2 Storyboard artist', '#3 Storyboard animation']
    },
    {
        label: 'Social Media Designs - Headers & Covers',
        value: 'SOCIAL_MEDIA_DESIGNS_HEADERS_COVERS',
        keywords: ['#1 YouTube banner and logo', '#2 YouTube banner', '#3 Facebook banner', '#4 Facebook cover', '#5 Facebook cover photo', '#6 Logo and banner', '#7 Social media banner', '#8 Facebook cover design', '#9 YouTube channel art', '#10 Banner', '#11 LinkedIn banner', '#12 YouTube branding', '#13 Twitter banner', '#14 YouTube gaming banner', '#15 Discord banner', '#16 YouTube banner design', '#17 YouTube channel banner', '#18 Zoom background', '#19 YouTube cover', '#20 Facebook banner design']
    },
    {
        label: 'Social Media Designs - Social Posts & Banners',
        value: 'SOCIAL_MEDIA_DESIGNS_SOCIAL_POSTS_BANNERS',
        keywords: ['#1 Facebook ad creative', '#2 Ad design', '#3 Ad creative', '#4 Facebook ads design', '#5 Image ads', '#6 Facebook ad image', '#7 Banner', '#8 Graphic design social media', '#9 Social media post', '#10 YouTube banner', '#11 Social media advertising', '#12 Social media post design', '#13 Canva design', '#14 Canva templates', '#15 Instagram infographics', '#16 LinkedIn banner', '#17 Design creative Facebook ads', '#18 Instagram design', '#19 Social media ad design', '#20 Instagram post']
    },
    {
        label: 'Social Media Designs - Thumbnails Design',
        value: 'SOCIAL_MEDIA_DESIGNS_THUMBNAILS_DESIGN',
        keywords: ['#1 YouTube thumbnail', '#2 Thumbnail', '#3 YouTube thumbnail design', '#4 Roblox thumbnail', '#5 Thumbnail design', '#6 Draw thumbnail', '#7 Gaming YouTube thumbnail', '#8 Gaming thumbnail', '#9 Miniatura YouTube', '#10 Cartoon thumbnail', '#11 Cartoon YouTube thumbnail', '#12 YouTube video thumbnail', '#13 Miniature YouTube', '#14 Call of Duty thumbnail', '#15 Minecraft thumbnail', '#16 Create YouTube thumbnail', '#17 Best YouTube thumbnails design', '#18 Valorant thumbnail', '#19 Thumbnail horror', '#20 Sports thumbnail']
    },
    {
        label: 'Catalog Design',
        value: 'CATALOG_DESIGN',
        keywords: ['#1 Magazine design', '#2 Product catalog', '#3 Magazine', '#4 Catalog design', '#5 Product catalog design', '#6 Magazine layout', '#7 Lookbook', '#8 Catalogue design', '#9 Catalog']
    },
    {
        label: 'Menu Design',
        value: 'MENU_DESIGN',
        keywords: ['#1 Menu design', '#2 Restaurant menu', '#3 Restaurant menu design', '#4 Menu', '#5 Digital menu', '#6 Digital menu board', '#7 Food menu design', '#8 Food menu', '#9 Menu card']
    },
    {
        label: 'Invitation Design',
        value: 'INVITATION_DESIGN',
        keywords: ['#1 Invitation card', '#2 Wedding invitation', '#3 Birthday invitation', '#4 Invitation design', '#5 Invitation', '#6 Christmas card design', '#7 Greeting card', '#8 Digital invitation', '#9 Wedding card', '#10 Christmas card', '#11 Digital wedding invitation', '#12 Indian wedding invitation', '#13 Save the date', '#14 Birthday card', '#15 Wedding invitation card', '#16 Indian wedding invitation video']
    },
    {
        label: 'Portraits & Caricatures',
        value: 'PORTRAITS_CARICATURES',
        keywords: ['#1 Caricature', '#2 Pet illustration', '#3 Portrait illustration', '#4 Dog illustration', '#5 Cartoon portrait', '#6 Avatar', '#7 Portrait', '#8 Pet portrait', '#9 Realistic drawing', '#10 Simpsons', '#11 Pop art', '#12 Portrait painting', '#13 Painting', '#14 Cartoon photo', '#15 Cartoon caricature', '#16 Dog portrait', '#17 Realistic portrait', '#18 Oil painting', '#19 Photo illustration', '#20 Family portrait']
    },
    {
        label: 'Cartoons & Comics',
        value: 'CARTOONS_COMICS',
        keywords: ['#1 Comic book illustration', '#2 Comic', '#3 Comic book artist', '#4 Cartoon character', '#5 Manga', '#6 Cartoon drawing', '#7 Comic illustration', '#8 Comic strip', '#9 Superhero', '#10 Comic art', '#11 Comic book', '#12 Manga comic', '#13 Comic book cover', '#14 Webtoon', '#15 Graphic novel', '#16 Draw manga', '#17 Superhero character', '#18 Comic cover', '#19 Comic page', '#20 Manga page']
    },
    {
        label: 'Tattoo Design',
        value: 'TATTOO_DESIGN',
        keywords: ['#1 Tattoo design', '#2 Tattoo drawing', '#3 Tattoo', '#4 Traditional tattoo', '#5 Tattoo artist', '#6 Custom tattoo design', '#7 Tattoo sleeve', '#8 Anime tattoo', '#9 Minimalist tattoo', '#10 Japanese tattoo']
    },
    {
        label: 'Signage Design',
        value: 'SIGNAGE_DESIGN',
        keywords: ['#1 Banner design', '#2 Billboard design', '#3 Roll up banner design', '#4 Roll up banner', '#5 Roll up', '#6 Signage design', '#7 Retractable banner', '#8 Yard sign', '#9 Signage']
    },
    {
        label: 'Web Banners',
        value: 'WEB_BANNERS',
        keywords: ['#1 Website banner design', '#2 Banner ads', '#3 Website banner', '#4 Shopify banner', '#5 Web banner design', '#6 Website header', '#7 Google banner ads', '#8 Web banner', '#9 Hero image', '#10 Google display ads', '#11 Google ads design', '#12 Display ads', '#13 Etsy banner', '#14 Etsy shop banner', '#15 Animated GIF banner']
    },
    {
        label: 'Image Editing - Product Image Editing',
        value: 'IMAGE_EDITING_PRODUCT_IMAGE_EDITING',
        keywords: ['#1 Amazon A+ content', '#2 Amazon listing images', '#3 Amazon images', '#4 Amazon product photos', '#5 A+ content', '#6 Product photo editing', '#7 Product photo', '#8 Amazon photo editing', '#9 Amazon EBC', '#10 Product photo retouching', '#11 Resize image', '#12 Amazon listing pictures', '#13 Amazon product photo editing', '#14 Background removal', '#15 Photo background removal', '#16 Remove background from image', '#17 Amazon pictures', '#18 Amazon product design', '#19 Jewelry retouch', '#20 Listing images']
    },
    {
        label: 'Image Editing - Photo Manipulation',
        value: 'IMAGE_EDITING_PHOTO_MANIPULATION',
        keywords: ['#1 Photoshop', '#2 Photoshop editing', '#3 Photo editing', '#4 Real estate photo editing', '#5 Picture editing', '#6 Photo manipulation', '#7 Image editing', '#8 Photoshop document editing', '#9 Photoshop remove', '#10 Photoshop manipulation', '#11 Photoshop picture', '#12 Photo collage', '#13 Photo shop', '#14 Remove object from photo', '#15 Collage', '#16 Photoshop face swap', '#17 Photoshop expert', '#18 Photo composition', '#19 Face swap', '#20 Photo color grading']
    },
    {
        label: 'Image Editing - Portraits Retouching',
        value: 'IMAGE_EDITING_PORTRAITS_RETOUCHING',
        keywords: ['#1 Photo retouching', '#2 Wedding photo editing', '#3 Headshot editing', '#4 Image editing', '#5 Portrait photo editing', '#6 Retouch', '#7 Bulk photo editing', '#8 Headshot retouch', '#9 Portrait editing', '#10 Photo editing Lightroom', '#11 Photo touch up', '#12 Portrait retouching', '#13 Photo', '#14 Skin retouching', '#15 Lightroom', '#16 Headshot', '#17 Beauty retouch', '#18 Family photo editing', '#19 Wedding album design', '#20 Photo color grading']
    },
    {
        label: 'Image Editing - Photo Restoration',
        value: 'IMAGE_EDITING_PHOTO_RESTORATION',
        keywords: ['#1 Photo restoration', '#2 Colorize photo', '#3 Photo enhance', '#4 Restore old photos', '#5 Upscale image', '#6 Photoshop document editing', '#7 Fix blurry photo', '#8 Photo sharpening']
    },
    {
        label: 'Image Editing - Mockups',
        value: 'IMAGE_EDITING_MOCKUPS',
        keywords: ['#1 T-shirt mockup', '#2 Product mockup', '#3 Book mockup', '#4 3D mockup', '#5 Mockup', '#6 3D T-shirt mockup', '#7 3D product mockup', '#8 3D book cover mockup']
    },
    {
        label: 'Architecture & Interior Design - 2D Drawings & Floor Plans',
        value: 'ARCHITECTURE_INTERIOR_DESIGN_2D_DRAWINGS_FLOOR_PLANS',
        keywords: ['#1 Architectural plans', '#2 Architectural drawing', '#3 Blueprint', '#4 Floor plan', '#5 Architecture', '#6 Architect', '#7 AutoCAD drawings', '#8 CAD drawing', '#9 Floor plan design', '#10 House plans', '#11 Floor plan drawing', '#12 Drafting', '#13 2D floor plan', '#14 Architectural floor plan', '#15 Draftsman', '#16 Building plans', '#17 AutoCAD', '#18 AutoCAD drafting', '#19 Construction drawing', '#20 Revit']
    },
    {
        label: 'Architecture & Interior Design - 3D Modeling & Rendering',
        value: 'ARCHITECTURE_INTERIOR_DESIGN_3D_MODELING_RENDERING',
        keywords: ['#1 Render', '#2 Interior design', '#3 3D rendering architecture', '#4 Architectural rendering', '#5 3D floor plan', '#6 Architecture design', '#7 Interior rendering', '#8 House rendering', '#9 3D interior design', '#10 Exterior rendering', '#11 3D house design', '#12 House design', '#13 Interior design 3D rendering', '#14 Sketchup 3D model', '#15 Architecture', '#16 Sketchup', '#17 3D modeling architecture', '#18 3D architectural visualization', '#19 3D model house', '#20 3D rendering interior']
    },
    {
        label: 'Architecture & Interior Design - Planning & Design',
        value: 'ARCHITECTURE_INTERIOR_DESIGN_PLANNING_DESIGN',
        keywords: ['#1 Interior decorator', '#2 Interior design IKEA', '#3 Interior design shopping list', '#4 Lighting design', '#5 Interior design mood board', '#6 Architecture', '#7 Mood board']
    },
    {
        label: 'Architecture & Interior Design - Virtual Staging',
        value: 'ARCHITECTURE_INTERIOR_DESIGN_VIRTUAL_STAGING',
        keywords: ['#1 Virtual staging']
    },
    {
        label: 'Architecture & Interior Design - Diagrams & Mapping',
        value: 'ARCHITECTURE_INTERIOR_DESIGN_DIAGRAMS_MAPPING',
        keywords: ['#1 Architecture diagram']
    },
    {
        label: 'Landscape Design',
        value: 'LANDSCAPE_DESIGN',
        keywords: ['#1 Garden design', '#2 Site plan', '#3 Landscape design', '#4 Landscape', '#5 Backyard design', '#6 Landscape architect', '#7 Backyard landscape design']
    },
    {
        label: 'Character Modeling',
        value: 'CHARACTER_MODELING',
        keywords: ['#1 3D model for printing', '#2 3D modeling', '#3 3D sculpting', '#4 3D character', '#5 3D character modeling', '#6 VRChat avatar', '#7 Anime 3D model', '#8 3D VTuber', '#9 3D VTuber model', '#10 3D model rigging', '#11 VRChat', '#12 Sculpting', '#13 3D game character', '#14 3D character design', '#15 Metahuman', '#16 ZBrush', '#17 3D cartoon', '#18 VRC avatar', '#19 Character modeling', '#20 Retopology']
    },
    {
        label: 'Industrial & Product Design - Concept Development',
        value: 'INDUSTRIAL_PRODUCT_DESIGN_CONCEPT_DEVELOPMENT',
        keywords: ['#1 Product sketch', '#2 Industrial design', '#3 Product development']
    },
    {
        label: 'Industrial & Product Design - Technical Drawing',
        value: 'INDUSTRIAL_PRODUCT_DESIGN_TECHNICAL_DRAWING',
        keywords: ['#1 Shoe design', '#2 Furniture design', '#3 Shoes']
    },
    {
        label: 'Industrial & Product Design - 3D Modeling & Rendering',
        value: 'INDUSTRIAL_PRODUCT_DESIGN_3D_MODELING_RENDERING',
        keywords: ['#1 3D product rendering', '#2 Product rendering', '#3 3D product modeling', '#4 3D design', '#5 3D rendering for Amazon', '#6 3D product', '#7 3D product design', '#8 CAD design', '#9 CAD', '#10 3D CAD modeling', '#11 3D car modeling', '#12 SolidWorks', '#13 Furniture design', '#14 ANSYS', '#15 3D modeling furniture', '#16 3D CAD design', '#17 3D CAD', '#18 Car modeling', '#19 Fusion 360']
    },
    {
        label: 'Industrial & Product Design - Rapid Prototyping',
        value: 'INDUSTRIAL_PRODUCT_DESIGN_RAPID_PROTOTYPING',
        keywords: ['#1 3D printing', '#2 3D Druck', '#3 3D printing service', '#4 STL file']
    },
    {
        label: 'Industrial & Product Design - Design For Manufacturing',
        value: 'INDUSTRIAL_PRODUCT_DESIGN_DESIGN_FOR_MANUFACTURING',
        keywords: ['#1 CNC', '#2 Industrial design', '#3 CNC programming', '#4 Prototype']
    },
    {
        label: 'Industrial & Product Design - Full Design Process',
        value: 'INDUSTRIAL_PRODUCT_DESIGN_FULL_DESIGN_PROCESS',
        keywords: ['#1 Industrial design']
    },
    {
        label: 'Trade Booth Design',
        value: 'TRADE_BOOTH_DESIGN',
        keywords: ['#1 Trade show banner', '#2 Trade show']
    },
    {
        label: 'T-Shirts & Merchandise',
        value: 'TSHIRTS_MERCHANDISE',
        keywords: ['#1 Clothing design', '#2 Graphic T-shirt design', '#3 Merch design', '#4 T-shirt design', '#5 T-shirt logo design', '#6 T shirt', '#7 T-shirt illustration', '#8 Clothing graphic design', '#9 Hoodie design', '#10 Streetwear design', '#11 Design', '#12 Streetwear clothing design', '#13 Jersey design', '#14 Vintage T shirt design', '#15 Vintage T-shirt', '#16 Merch by Amazon', '#17 Clothing brand', '#18 Clothing brand design', '#19 Clothing brand graphic designer']
    },
    {
        label: 'Fashion Design',
        value: 'FASHION_DESIGN',
        keywords: ['#1 Tech pack', '#2 Bag design', '#3 Clothing tech pack', '#4 Fashion tech pack', '#5 Fashion technical drawing', '#6 Bag tech pack', '#7 Fashion design collection', '#8 Handbag design', '#9 Flat sketch', '#10 Fashion', '#11 Techpack', '#12 Shoe tech pack']
    },
    {
        label: 'Presentation Design',
        value: 'PRESENTATION_DESIGN',
        keywords: ['#1 PowerPoint', '#2 Pitch deck', '#3 Presentation design', '#4 PowerPoint presentation', '#5 Google Slides', '#6 PowerPoint template design', '#7 Presentation', '#8 PowerPoint animation', '#9 Pitch deck design', '#10 PowerPoint template', '#11 PowerPoint presentation design', '#12 PowerPoint slides', '#13 Canva presentation', '#14 PowerPoint-Präsentation', '#15 PowerPoint redesign', '#16 Power point', '#17 Professional PowerPoint presentation', '#18 Architecture portfolio', '#19 Presentation slides']
    },
    {
        label: 'E-mail Design',
        value: 'EMAIL_DESIGN',
        keywords: ['#1 Klaviyo email design', '#2 Email design']
    },
    {
        label: 'Icon Design',
        value: 'ICON_DESIGN',
        keywords: ['#1 Icons', '#2 Instagram highlights', '#3 Icon set', '#4 Custom icons', '#5 Vector icon', '#6 Favicon', '#7 Instagram highlight icon', '#8 3D icon']
    },
    {
        label: 'Infographic Design',
        value: 'INFOGRAPHIC_DESIGN',
        keywords: ['#1 Infographic design', '#2 Visio', '#3 Flowchart', '#4 Business infographic', '#5 BOL infographic']
    },
    {
        label: 'Car Wraps',
        value: 'CAR_WRAPS',
        keywords: ['#1 Wrap design', '#2 Van wrap', '#3 Car wrap design', '#4 Vending machine wrap', '#5 Racing car wrap', '#6 Food truck wrap', '#7 FiveM livery', '#8 Van wrap design']
    },
    {
        label: 'Vector Tracing',
        value: 'VECTOR_TRACING',
        keywords: ['#1 Vector tracing', '#2 Vectorize image', '#3 Redraw logo', '#4 Adobe Illustrator', '#5 Vector file', '#6 Convert image to vector', '#7 PNG to vector', '#8 Recreate logo', '#9 Image tracing']
    },
    {
        label: 'NSFW Art',
        value: 'NSFW_ART',
        keywords: ['#1 Illustration NSFW', '#2 Furry NSFW', '#3 NSFW anime', '#4 NSFW', '#5 NSFW comic', '#6 NSFW manga', '#7 NSFW AI art', '#8 Yaoi']
    },
    {
        label: 'Graphic Design - Other',
        value: 'GRAPHIC_DESIGN_OTHER',
        keywords: ['#1 Graphic design', '#2 Graphiste', '#3 Barcode', '#4 Stock images', '#5 Mind map']
    }
];

const FiverrKeywordFinder = () => {
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [keywords, setKeywords] = useState([])

    const onGenerate = () => {
        if (selectedCategory) {
            setKeywords(selectedCategory.keywords)
        } else {
            toast.error('Please select a category')
        }
    }

    const onClipboardCopy = (data) => {
        navigator?.clipboard?.writeText(data)
        toast.success('Copied to clipboard')
    }

    return <Page id={'27'} title={'Fiverr Keyword Finder'}>
        <Row>
            <Col md={{size: 4, offset: 4}} className={'mb-4'}>
                <FormGroup>
                    <Label>Select Category</Label>
                    <Select options={categories} value={selectedCategory} onChange={e => setSelectedCategory(e)}/>
                </FormGroup>

                <Button color={'success'} className={'w-100'} onClick={onGenerate}>Generate</Button>
            </Col>

            {keywords?.length > 0 && <>
                <hr/>
                <Col md={12}>
                    <Row>
                        {
                            keywords.map((item, i) => <Col key={i} md={4} onClick={() => onClipboardCopy(item)}
                                                           style={{cursor: 'pointer'}} id={`keyword${i}`}>
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`keyword${i}`}
                                >
                                    Click to copy
                                </UncontrolledTooltip>

                                <Alert>{item}</Alert>
                            </Col>)
                        }
                    </Row>
                </Col>
            </>}

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <p>
                        Unlock the potential of Fiverr Keyword Finder, the indispensable tool for pinpointing the most
                        impactful keywords on Fiverr. Whether you're a freelancer, entrepreneur, or seeking to enhance
                        your online visibility, our tool provides the key to optimizing your presence on the Fiverr
                        platform.
                    </p>
                    <h5>Here's how it works: </h5>
                    <p>
                        Fiverr Keyword Finder grants you the ability to effortlessly identify high-ranking keywords.
                        This intuitive tool streamlines your search, enabling you to locate the most relevant keywords
                        that resonate with your services or products on Fiverr.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Precise keyword identification for enhanced discoverability</li>
                        <li>Seamless and user-friendly interface</li>
                        <li>Real-time insights to boost your Fiverr visibility</li>
                    </ul>

                    <p>
                        Explore the ease and efficiency of Fiverr Keyword Finder today! Elevate your Fiverr listings
                        with precision and accuracy in keyword targeting. Take charge of your online success and harness
                        the potential of Fiverr Keyword Finder to revolutionize your Fiverr journey.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>
}

export default FiverrKeywordFinder