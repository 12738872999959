import React, {useState} from "react";
import Page from "../../components/page";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {freepikUrl} from '../../const/regex'
import '../../assets/styles/common.scss'
import {toast} from 'react-toastify';
import errorMessages from "../../const/errorMesages";
import {saveAs} from 'file-saver'

const FreepikImageDownloader = (props) => {
    const baseURL = 'https://img.youtube.com/vi/'

    const [url, setUrl] = useState(null)
    const [thumbnailUrl, setThumbnailUrl] = useState([])
    const [isFetched, setIsFetched] = useState(false)

    const onUrlHandler = () => {
        const isValid = freepikUrl.test(url?.trim())
        if (isValid) {
            const imgUrl = url.trim()
            const splited = imgUrl.split('?w=')

            setThumbnailUrl(splited[0])
            setIsFetched(true)
        } else {
            toast.error(errorMessages.validUrl, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    const clear = () => {
        setIsFetched(false)
        setThumbnailUrl([])
        setUrl(null)
    }

    const onDownloadThumbnail = async (url) => {
        saveAs(url, "Twitter-logo");
    }

    return <Page id={'20'} title={'Freepik Premium Image Downloader'}>
        <Row>
            <Col md={12}>
                {!isFetched ? <div className={'url-section'} align={'center'}>
                    <Label>Enter the link of a Freepik image below</Label>
                    <Input autoFocus onChange={(e) => setUrl(e.target.value)}
                           placeholder={'Copy & Past Any Freepik Image Link Here '}/>
                    <Button onClick={onUrlHandler} color={'primary'}>Fetch Image</Button>
                </div> : <div className={'preview-section'} align={'center'}>
                    <Button onClick={clear} className={'btn-fetch'} style={{marginBottom: 20}}>Fetch Another
                        Image</Button>
                    <Row>
                        <Col md={{
                            size: 6,
                            offset: 3
                        }}>
                            <div className={'thumbnail-section'} align={'center'}>
                                <img style={{height: 500, objectFit: 'cover'}} src={thumbnailUrl}
                                     alt={`freepik-premium-image`}/>
                                <Button color={'success'}
                                        onClick={() => onDownloadThumbnail(thumbnailUrl)}
                                >Download</Button>
                            </div>
                        </Col>
                    </Row>
                </div>}
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Freepik offers premium images for download, and users must have a valid premium subscription or
                        purchase the images individually to access them legally and use them without watermarks. This
                        tool
                        allows users to get freepik images without a watermark.
                    </p>

                    <p>
                        If you are interested in using premium images from Freepik or any other website, We encourage
                        you to
                        follow their terms of service, pay for the premium content, and use the images in accordance
                        with
                        the licensing and usage guidelines provided by the website.
                    </p>

                    <p>
                        Always respect copyright laws and the rights of content creators to ensure fair use and promote
                        a
                        healthy and ethical online environment.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>
}

export default FreepikImageDownloader