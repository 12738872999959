import React, {useEffect, useState} from "react";
import Navbar from "../components/navbar";
import {Col, Container, Row} from "reactstrap";
import {Star, Grid} from 'react-feather'
import Card from '../components/card'
import Footer from '../components/footer'
import {CATEGORIES, toolList} from "../const/const";
import {getFavoriteList} from "../utils/commonFunc";
import CategoryBadge from "../components/categoryBadge";
import {AnimatePresence, motion} from 'framer-motion'
import TopBanner from "../components/topBanner";

const Index = () => {
    const [favoriteList, setFavoriteList] = useState([])
    const [tempToolList, setTempToolList] = useState([])

    useEffect(() => {
        setTempToolList(toolList)
        filterFavoriteList()
    }, [])

    const filterFavoriteList = () => {
        const list = getFavoriteList()
        const tempList = []
        list.map((toolId, i) => {
            const isFound = toolList.filter(item => toolId === item.id)
            if (isFound.length > 0) tempList.push(isFound[0])
        })
        setFavoriteList(tempList)
    }

    const visible = {opacity: 1, y: 0, transition: {duration: 0.5}};

    return <div>
        <motion.article
            initial="hidden"
            animate="visible"
            exit={{opacity: 0, transition: {duration: 1}}}
            variants={{visible: {transition: {staggerChildren: 0.3}}}}
        >
            <Navbar/>
            <motion.div
                variants={{
                    hidden: {opacity: 0, y: -20},
                    visible
                }}
            >
                <TopBanner/>
                <Container style={{minHeight: 500, padding: '0 30px'}}>
                    <CategoryBadge
                        className={'mt-5 mb-2'}
                        toolList={toolList}
                        onChange={setTempToolList}
                    />

                    {/*<Row style={{marginTop: 10, paddingBottom: favoriteList.length > 0 ? 50 : 0}}>*/}
                    {/*    {favoriteList.length > 0 && <h4><Star style={{marginTop: -5}}/> Favorite Tools</h4>}*/}

                    {/*    {*/}
                    {/*        favoriteList.map((item, i) => <Col key={i} sm={6} md={4} lg={3}>*/}
                    {/*            <Card id={item.id} url={item.url} image={item.image} title={item.title}/>*/}
                    {/*        </Col>)*/}
                    {/*    }*/}
                    {/*</Row>*/}

                    <Row>
                        {/*<h4><Grid style={{marginTop: -5}}/> All Tools</h4>*/}

                        <AnimatePresence>
                            {
                                tempToolList.map((item, i) => <Col key={i} sm={6} md={4} lg={3}>
                                    <motion.div
                                        key={item.id}
                                        initial={{y: 10, opacity: 0}}
                                        animate={{y: 0, opacity: 1}}
                                        exit={{y: -10, opacity: 0}}
                                        transition={{duration: 0.5}}
                                    >
                                        <Card
                                            id={item.id}
                                            url={item.url}
                                            image={item.image}
                                            title={item.title}
                                            isNew={item.isNew}
                                            desc={item.desc}
                                        />
                                    </motion.div>
                                </Col>)
                            }
                        </AnimatePresence>
                    </Row>
                </Container>

                <Footer/>
            </motion.div>
        </motion.article>
    </div>
}

export default Index