import React, {useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss';
import '../../assets/styles/qrGenerator.scss';
import {Button, Col, Input, Row} from "reactstrap";
import {QRCode} from 'react-qrcode-logo';
import initialLogo from '../../assets/img/logo-initial.png'
import {downloadCanvas} from "../../utils/commonFunc";

const QrGenerator = (props) => {
    const [typeText, setTypeText] = useState('')
    const [data, setData] = useState('')

    const downloadQr = async () => {
        const a = document.getElementById("react-qrcode-logo");
        await downloadCanvas(a, 'image/png', typeText)
    }

    const isDisabled = typeText.trim().length === 0

    return (<Page id={'11'} title={'QR Generator'}>
        <Row>
            <Col md={6} className={'mb-4'}>
                <h5>Your Text</h5>
                <Input type={'textarea'} className={'height-250'}
                       onChange={(e) => setTypeText(e.target.value)}
                       value={typeText}/>

                <Button disabled={isDisabled} color={'success'} className={'mt-3'} style={{width: '100%'}}
                        onClick={() => setData(typeText)}>Generate</Button>
                <Button className={'mt-2'} disabled={isDisabled} style={{width: '100%'}} onClick={() => {
                    setTypeText('')
                    setData('')
                }}>Clear</Button>
            </Col>

            <Col md={6} className={'mb-4'}>
                <h5>Generated QR</h5>
                <div className={'qr-container'} align={'center'}>
                    <QRCode
                        value={data === '' ? 'https://toolprefix.com' : data}
                        logoImage={initialLogo}
                        logoWidth={40}
                        logoHeight={40}
                        size={200}
                    />

                    {data && <Button color={'primary'} className={'mt-2'} style={{width: '100%'}}
                                     onClick={downloadQr}>Download</Button>}
                </div>
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        QR codes have become an integral part of modern communication, allowing quick and easy access to
                        information using smartphones and other devices. With our user-friendly interface, generating QR
                        codes is now simpler than ever before.
                    </p>

                    <p>
                        Whether you're a business owner, marketer, or simply someone looking to share information
                        conveniently, our website offers a seamless and efficient solution. Simply enter your text,
                        link, or
                        paragraph into the designated area, and with a single click of the "Generate" button, your
                        personalized QR code will be ready for use.
                    </p>

                    <p>
                        Our Online QR Generator ensures a quick and reliable QR code generation process. You don't need
                        to
                        be a tech expert or install any software to use it - just type, click, and your QR code is
                        generated
                        instantly!
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Effortless QR code generation from text, links, or paragraphs</li>
                        <li>No installation or technical expertise required</li>
                        <li>Quick and user-friendly interface</li>
                        <li>Reliable and efficient QR code generation process</li>
                    </ul>

                    <p>
                        Experience the convenience and power of our Online QR Generator today! Create personalized QR
                        codes
                        for text, links, or paragraphs in seconds and make information sharing more accessible than
                        ever.
                        Get started now and discover the endless possibilities of QR codes with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default QrGenerator