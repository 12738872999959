import React from "react";
import Page from "../components/page";
import '../assets/styles/aboutUs.scss'

const AboutUs = () => {
    return <Page socialShare={false} title={'Use of Cookies'}>
        <p>Simply, cookies are only used to keep the favorite tool list that you use on the site. And it is only saved
            when you add it to the list yourself, otherwise, it will not save anything.</p>
    </Page>
}

export default AboutUs