import React, {useRef, useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, Input, Label, Row} from "reactstrap";
import {Doughnut, Pie, Bar, Line, Bubble, Radar, Scatter, PolarArea} from 'react-chartjs-2';
import {Chart as ChartJS, registerables} from 'chart.js';
import {SliderPicker} from 'react-color'
import {Trash} from 'react-feather'
import {toast} from "react-toastify";
import errorMessages from "../../const/errorMesages";
import {downloadElement} from "../../utils/commonFunc";
import Select from 'react-select'

ChartJS.register(...registerables);
const sampleData = {
    id: 1,
    label: 'Sample',
    value: 5,
    color: '#ff44ff'
}

const options = [
    {label: 'Doughnut', value: 'Doughnut'},
    {label: 'Pie', value: 'Pie'},
    {value: 'Bar', label: 'Bar'},
    {value: 'Line', label: 'Line'},
    // {value: 'Bubble', label: 'Bubble'},
    {value: 'Radar', label: 'Radar'},
    // {label: 'Scatter', value: 'Scatter'},
    {value: 'PolarArea', label: 'PolarArea'}
]

const ChartsGenerator = (props) => {
    const [rows, setRows] = useState([sampleData])
    const [type, setType] = useState(options[0])
    const [title, setTitle] = useState('ToolPrefix')
    const domEl = useRef(null);

    const data = {
        labels: rows.map(item => item.label),
        datasets: [
            {
                label: title,
                data: rows.map(item => item.value),
                backgroundColor: rows.map(item => item.color),
                // borderColor: [
                //     'rgba(255, 99, 132, 1)',
                //     'rgba(54, 162, 235, 1)',
                //     'rgba(255, 206, 86, 1)',
                //     'rgba(75, 192, 192, 1)',
                //     'rgba(153, 102, 255, 1)',
                //     'rgba(255, 159, 64, 1)',
                // ],
                borderWidth: 1,
            },
        ],
    };

    const onChange = (type, index, value) => {
        const tempRow = [...rows]
        switch (type) {
            case 'label':
                tempRow[index].label = value.target.value
                setRows(tempRow)
                break

            case 'value':
                tempRow[index].value = value.target.value
                setRows(tempRow)
                break

            case 'color':
                tempRow[index].color = value.hex
                setRows(tempRow)
                break
        }
    }

    const addMore = () => {
        const tempRow = [...rows]
        let id = tempRow[tempRow.length - 1].id
        tempRow.push({...sampleData, id: id + 1})
        setRows(tempRow)
    }

    const removeRow = (index) => {
        if (rows.length > 1) {
            const tempRow = rows.filter((item, i) => index !== i)
            setRows(tempRow)
        } else {
            toast.error(errorMessages.lastRow, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    const downloadChart = async () => {
        await downloadElement(domEl, `${type.value} chart`)
    }

    return (<Page id={'16'} title={'Charts Generator'}>
        <Row>
            <Col md={5} className={'mb-4'}>
                <h5>Generated Chart</h5>

                <div className={'border-card'} id={'chart-section'}
                     style={{paddingBottom: 20, textAlign: 'center'}}>
                    <div ref={domEl}>
                        <label style={{color: '#fff'}}>toolprefix.com</label>
                        {type.value === options[0].value ? <Doughnut data={data}/>
                            : type.value === options[1].value ? <Pie data={data}/>
                                : type.value === options[2].value ? <Bar data={data}/>
                                    : type.value === options[3].value ? <Line data={data}/>
                                        : type.value === options[4].value ? <Radar data={data}/>
                                            : type.value === options[5].value ? <PolarArea data={data}/> : null}
                    </div>
                </div>
                <Button className={'mt-2'} color={'primary'} style={{width: '100%'}}
                        onClick={downloadChart}>Download</Button>
            </Col>

            <Col md={7} className={'mb-4'}>
                <h5>Choose Options</h5>
                <div className={'border-card'}>
                    <Row>
                        <Col md={6}>
                            <Label>Chart Type</Label>
                            <Select value={type} options={options} onChange={setType}/>
                        </Col>

                        <Col md={6}>
                            <Label>Title</Label>
                            <Input value={title} onChange={(e) => setTitle(e.target.value)}/>
                        </Col>
                    </Row>
                </div>

                <div className={'border-card'}>
                    <h6>Data</h6>
                    {
                        rows.map((item, i) => <Row key={i} className={'data-row'}>
                            <Col md={4} className={'data-section'}>
                                <Input onChange={e => onChange('label', i, e)} value={item.label}
                                       placeholder={'Label'}/>
                            </Col>

                            <Col md={2} className={'data-section'}>
                                <Input onChange={e => onChange('value', i, e)} value={item.value} type={'number'}
                                       placeholder={'Value'}/>
                            </Col>

                            <Col md={4} className={'data-section'}>
                                <SliderPicker onChange={e => onChange('color', i, e)} color={item.color}/>
                            </Col>

                            <Col md={2} className={'data-section'}>
                                <Trash onClick={() => removeRow(i)}/>
                            </Col>
                        </Row>)
                    }
                </div>

                <Button color={'success'} className={'mt-3'} onClick={addMore}
                        style={{width: '100%'}}>Add More</Button>
                <Button className={'mt-2'} style={{width: '100%'}} onClick={() => {
                    setRows([sampleData])
                }}>Clear</Button>
            </Col>

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Whether you're a data analyst, business owner, or simply someone looking to visualize
                        information in
                        a meaningful way, our user-friendly interface makes chart creation a breeze.
                    </p>

                    <p>
                        With our Charts Generator, you can enter a name and corresponding number value, and customize
                        the
                        chart's color and type to suit your preferences. Whether you need a pie chart, bar graph, line
                        chart, or more, our website offers a wide range of chart types to choose from.
                    </p>

                    <p>
                        Our intuitive interface allows you to create stunning charts in just a few simple steps. Enter
                        your
                        data, choose your desired chart type, and customize the color scheme to match your brand or
                        personal
                        style. With real-time previews, you can see your chart come to life as you make changes.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Effortless chart creation with name and number values</li>
                        <li>Wide selection of chart types: Pie, Bar, Line, and more</li>
                        <li>Customizable color schemes to match your brand</li>
                        <li>Quick and user-friendly interface</li>
                        <li>Real-time previews for instant visualization</li>
                    </ul>

                    <p>
                        Experience the convenience and power of our Online Charts Generator today! Visualize your data
                        in a
                        compelling way, making insights easily digestible and actionable. Get started now and unlock the
                        potential of data visualization with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default ChartsGenerator