import React, {useRef, useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Button, Col, Input, Row} from "reactstrap";
import JsonFormatter from 'react-json-formatter'
import ReactJson from "react-json-view";

const JsonFormatterView = (props) => {
    const [data, setData] = useState(null)
    const [typeText, setTypeText] = useState('{}')

    const jsonStyle = {
        propertyStyle: {color: 'red'},
        stringStyle: {color: 'green'},
        numberStyle: {color: 'darkorange'}
    }

    const format = () => {
        if (typeText.trim() === '') {

        } else {
            console.log('sdf')
            setData(typeText)
        }
    }


    return (<Page>
        <Row>
            <Col md={6} className={'mb-4'}>
                <h5>JSON Text</h5>
                <Input type={'textarea'} className={'height-200'} onChange={(e) => setTypeText(e.target.value)}/>
                <Button color={'success'} onClick={format} className={'mt-3'} style={{width: '100%'}}>Format</Button>
                <Button className={'mt-2'} style={{width: '100%'}} onClick={() => {
                    setData('')
                    setTypeText('')
                }}>Clear</Button>
            </Col>

            <Col md={6} className={'mb-4'}>
                <h5>Formatted JSON Text</h5>
                {data ? <ReactJson
                    src={data}
                    style={{width: '100%', wordBreak: 'break-all'}}
                /> : <div>No data available</div>}
            </Col>
        </Row>
    </Page>);
}

export default JsonFormatterView