import React, {useEffect, useRef, useState} from "react";
import Page from "../../components/page";
import '../../assets/styles/common.scss'
import {Alert, Button, Col, Input, Label, Row} from "reactstrap";
import {SliderPicker} from 'react-color'

const ColorPicker = (props) => {
    const [color, setColor] = useState({
        hex: '#0091E7'
    })

    const onChangeColor = (e) => {
        console.log(e)
        setColor(e)
    }

    return (<Page id={'19'} title={'Color Picker'}>
        <Row>
            <Col md={12} className={'mt-4 mb-5'}>
                <div style={{
                    backgroundColor: color.hex,
                    width: '100%',
                    height: 100,
                    marginBottom: 30,
                    borderRadius: 6
                }}></div>
                <SliderPicker onChange={onChangeColor} color={color}/>
            </Col>

            {color && <Col md={12}>
                <Row>
                    <Col md={4} className={'mb-3'}>
                        <div className={'color-container'}>
                            <Label>Hex</Label>
                            <Input readOnly value={color?.hex}/>
                        </div>
                    </Col>

                    <Col md={4} className={'mb-3'}>
                        <div className={'color-container'}>
                            <Row>
                                <Col md={4}>
                                    <Label>H</Label>
                                    <Input readOnly
                                           value={color?.hsv?.h ? Number.parseFloat(color?.hsv?.h).toFixed(2) : 0}/>
                                </Col>

                                <Col md={4}>
                                    <Label>S</Label>
                                    <Input readOnly
                                           value={color?.hsv?.s ? Number.parseFloat(color?.hsv?.s).toFixed(2) : 0}/>
                                </Col>

                                <Col md={4}>
                                    <Label>V</Label>
                                    <Input readOnly
                                           value={color?.hsv?.v ? Number.parseFloat(color?.hsv?.v).toFixed(2) : 0}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className={'color-container'}>
                            <Row>
                                <Col md={4}>
                                    <Label>R</Label>
                                    <Input readOnly value={color?.rgb?.r ? Number.parseInt(color?.rgb?.r) : 0}/>
                                </Col>

                                <Col md={4}>
                                    <Label>G</Label>
                                    <Input readOnly value={color?.rgb?.g ? Number.parseInt(color?.rgb?.g) : 0}/>
                                </Col>

                                <Col md={4}>
                                    <Label>B</Label>
                                    <Input readOnly value={color?.rgb?.b ? Number.parseInt(color?.rgb?.b) : 0}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Col>}

            <Col md={12} className={'mt-4'}>
                <hr/>
                <div className={'mt-3'}>
                    <h5>Here's how it works: </h5>
                    <p>
                        Our Color Picker offers a seamless experience, allowing you to choose colors from a spectrum and
                        instantly view their corresponding HEX, HSV, and RGB codes. Whether you're a designer,
                        developer, or
                        simply someone looking to find the perfect color for your project, our user-friendly interface
                        makes
                        it easy.
                    </p>

                    <p>
                        With our Color Picker, you can select colors by dragging the cursor across the spectrum or by
                        manually entering specific values. As you choose a color, the HEX, HSV, and RGB codes will be
                        displayed in real-time, giving you a comprehensive understanding of the selected color's code
                        representation.
                    </p>

                    <h5 className={'mt-2'}>Key Features:</h5>

                    <ul>
                        <li>Effortless color selection with HEX, HSV, and RGB code display</li>
                        <li>Quick and user-friendly interface</li>
                        <li>Real-time updates for immediate color exploration</li>
                        <li>Comprehensive color code representation</li>
                    </ul>

                    <p>
                        Experience the convenience and power of our Online Color Picker today! Find the perfect color
                        for
                        your designs, projects, or websites with ease. Get started now and unlock the potential of color
                        exploration with our user-friendly tool.
                    </p>
                </div>
            </Col>
        </Row>
    </Page>);
}

export default ColorPicker